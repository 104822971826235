import * as React from "react";
import Box from "@mui/material/Box";
import ArticleIcon from "@mui/icons-material/Article";
import axios from "axios";
import { alpha, darken } from "@mui/material/styles";
import {
  Modal,
  Menu,
  styled,
  Divider,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import CustomMenu from "./CustomMenu";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChatIcon from "@mui/icons-material/Chat";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import "../css/customScrollbar.css";
import { useState, useEffect } from "react";
import {
  Visibility as VisibilityIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  Delete as DeleteIcon,
  DeleteOutlineOutlined,
} from "@mui/icons-material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import HomeIcon from "@mui/icons-material/Home";
import QuizIcon from "@mui/icons-material/Quiz";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { dataSlice } from "../store/dataSlice";
import Profile from "./Profile";
import { useSelector } from "react-redux";
import { userSlice } from "../store/userSlice";
import { ShowToast } from "./ChatBoxWriteArticle";

import CreateNewProject from "./CreateNewProject";




const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: "#FFFFFF",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    "& .MuiMenu-list": {
      padding: 0,
    },
  },
}));
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: 14,
  padding: theme.spacing(1, 2),
  transition: "background-color 0.2s",
  color: "#000000",
  borderBottom: "1px solid #f1f1f1",
  display: "flex",
  justifyContent: "space-between",
  "&:hover": {
    backgroundColor: alpha("#2196F3", 0.1),
  },
}));

const NewProjectMenuItem = styled(StyledMenuItem)(({ theme }) => ({
  fontWeight: "bold",
  color: "#2196F3",
  borderBottom: "none",
}));

const ScrollableBox = styled(Box)({
  maxHeight: 250, // Adjust this value based on your needs
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
});

const ProjectNameBox = styled(Box)(({ theme }) => {
  const backgroundColor = getRandomColor();
  return {
    padding: theme.spacing(0.5, 1),
    borderRadius: 4,
    backgroundColor: backgroundColor,
    color: "#fff",
    transition: "background-color 0.2s",
    maxWidth: "150px", // Set a maximum width
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: darken(backgroundColor, 0.2),
    },
  };
});

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export default function MenuComponent() {
  const accessToken = useSelector((state) => state.user.accessToken);
  const navigate = useNavigate();
  const selectedPdfFiles = useSelector(
    (state) => state.data.selectedPdfFileNames
  );
  const [anchorElProjects, setAnchorElProjects] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const profileModalOpen = useSelector((state) => state.data.profileModalOpen);
  const dispatch = useDispatch();
  const whichPage = useSelector((state) => state.data.whichPage);
  const refetchGetAllPdfFiles = useSelector(
    (state) => state.data.refetchGetAllPdfFiles
  );
  const selectedProjectName = useSelector(
    (state) => state.user.selectedProjectName
  );
  const projects = useSelector((state) => state.user.projects);
  const [newProjectDialogOpen, setNewProjectDialogOpen] = useState(false);
  const [newProjectName, setNewProjectName] = useState("");

  const refetchGetAllProjects = useSelector(
    (state) => state.data.refetchGetAllProjects
  );

  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// functions ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    handleMobileMenuClose();

    dispatch(dataSlice.actions.setProfileModalOpen(false));
  };

  const handleNewProjectClick = () => {
    setNewProjectDialogOpen(true);
    handleProjectsMenuClose();
  };

  const handleProjectsMenuOpen = (event) => {
    setAnchorElProjects(event.currentTarget);
  };

  const handleProjectsMenuClose = () => {
    setAnchorElProjects(null);
  };

  const handleMenuItemClick = (projectName) => {
    if (projectName === selectedProjectName) {
    } else {
      dispatch(dataSlice.actions.setSelectedPdfFileNames([]));
      dispatch(userSlice.actions.setSelectedProjectName(projectName));
      dispatch(
        dataSlice.actions.setRefetchGetAllPdfFiles(!refetchGetAllPdfFiles)
      );

    }

    handleProjectsMenuClose();
    handleMenuClose();
  };

  const handleDeleteProject = async (project, event) => {
    event.stopPropagation();
    try {
      const projectId = project.id;
      const response = await axios.delete(
        `${REACT_APP_BACKEND_URL}api/pdf/project/${projectId}/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (selectedProjectName === project.name) {
        dispatch(userSlice.actions.setSelectedProjectName(null));
        dispatch(dataSlice.actions.setSelectedPdfFileNames([]));
      }

      dispatch(
        dataSlice.actions.setRefetchGetAllProjects(!refetchGetAllProjects)
      );
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  return (
    <>
      <Button
        onClick={handleProjectsMenuOpen}
        variant="contained"
        sx={{
          backgroundColor: "#4285F4",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#357ae8",
          },
          textTransform: "none",
        }}
        startIcon={<ArticleIcon />}
      >
        Projects
      </Button>
      <StyledMenu
        anchorEl={anchorElProjects}
        open={Boolean(anchorElProjects)}
        onClose={handleProjectsMenuClose}
      >
        <NewProjectMenuItem onClick={handleNewProjectClick}>
          New Project
        </NewProjectMenuItem>
        <Divider />
        <ScrollableBox>
          {projects.map((project) => (
            <StyledMenuItem
              key={project.name}
              onClick={() => handleMenuItemClick(project.name)}
            >
              <ProjectNameBox>{project.name}</ProjectNameBox>
              <IconButton
                sx={{
                  color: "#f44336",
                  backgroundColor: "#ffebee",
                  "&:hover": {
                    backgroundColor: "#ffcdd2",
                  },
                }}
                edge="end"
                color="inherit"
                onClick={(event) => handleDeleteProject(project, event)}
              >
                <DeleteOutlineOutlined
                  sx={{
                    fontSize: "1.2rem",
                  }}
                />
              </IconButton>
            </StyledMenuItem>
          ))}
        </ScrollableBox>
      </StyledMenu>

      <CreateNewProject
            newProjectDialogOpen = {newProjectDialogOpen}
            setNewProjectDialogOpen = {setNewProjectDialogOpen}
            newProjectName = {newProjectName}
            setNewProjectName = {setNewProjectName}
            dispatch = {dispatch}
            dataSlice = {dataSlice}
            accessToken = {accessToken}
            refetchGetAllProjects = {refetchGetAllProjects}
            userSlice = {userSlice}
            refetchGetAllPdfFiles = {refetchGetAllPdfFiles} 
      />
    </>
  );
}
