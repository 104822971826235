import React from "react";
import {
  Avatar,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import { handleSignOut, handleGoogleSignIn } from "../utils/Auth";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { dataSlice } from "../store/dataSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ProfileCard = styled(Card)(({ theme }) => ({
  maxWidth: 500,
  height: "95%",
  overflow: "auto",
  margin: "auto",
  marginTop: theme.spacing(1),
  textAlign: "center",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  borderRadius: theme.spacing(1),
  backgroundColor: "#ffffff",
  border: "1px solid #e0e0e0",
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(14),
  height: theme.spacing(14),
  margin: "auto",
  marginBottom: theme.spacing(2),
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
}));

const ProfileButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: "#007BFF",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#0056b3",
  },
}));

const SignOutButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: "#FF0000",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#cc0000",
  },
}));

const Profile = ({ handleClose }) => {
  const user = useSelector((state) => state.user.user);
  const [simpleUser, setSimpleUser] = useState(null);
  const accessToken = useSelector((state) => state.user.accessToken);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignoutWithNavigate = async () => {
    dispatch(dataSlice.actions.setProfileModalOpen(false));
    navigate("/");
    await handleGoogleSignIn();
  };

  const handleSignoutWithNavigateJWT = async () => {
    dispatch(dataSlice.actions.setProfileModalOpen(false));
    navigate("/auth");
    await handleSignOut();
    // await handleGoogleSignIn();
  };

  const handleSignOutClick = async () => {
    navigate("/auth");
    await handleSignOut();
  };
  const loadUser = async () => {
    try {
      const response = await axios.get(
        REACT_APP_BACKEND_URL + "api/auth/profile-simple",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = {
        displayName: response.data.name,
        email: response.data.email,
        photoURL: null,
        name: response.data.name,
      };

      setSimpleUser(data);
    } catch (error) {
      console.error("Error getting user:", error);
    }
  };

  useEffect(() => {
    if (accessToken) {
      loadUser();
    }
  }, [accessToken]);

  if (!user && !simpleUser) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#f0f2f5"
      >
        <CircularProgress />
      </Box>
    );
  } else if (user) {
    return (
      <ProfileCard>
        <CardContent>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <ProfileAvatar alt={user.displayName} src={user.photoURL} />
          <Typography variant="h5" component="div" gutterBottom>
            {user.displayName}
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {user.email}
          </Typography>

          <Box mt={2}>
            <ProfileButton
              variant="outlined"
              onClick={handleSignoutWithNavigate}
            >
              Switch Account
            </ProfileButton>
          </Box>

          <Box mt={2}>
            <SignOutButton variant="outlined" onClick={handleSignOutClick}>
              Sign Out
            </SignOutButton>
          </Box>

          {/* Account Details Section */}
          <Box mt={4} textAlign="left">
            <Typography variant="h6" gutterBottom>
              Account Details
            </Typography>
            <Divider />
            <List>
              <ListItem>
                <ListItemText
                  primary="Full Name"
                  secondary={user.displayName}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Email" secondary={user.email} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Phone"
                  secondary={user.phoneNumber || "N/A"}
                />
              </ListItem>
            </List>
          </Box>

          {/* Settings Section */}
          <Box mt={4} textAlign="left">
            <Typography variant="h6" gutterBottom>
              Settings
            </Typography>
            <Divider />
            <List>
              <ListItem button>
                <ListItemText primary="Change Password" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Notification Preferences" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Privacy Settings" />
              </ListItem>
            </List>
          </Box>

          {/* Activity Log Section */}
          <Box mt={4} textAlign="left">
            <Typography variant="h6" gutterBottom>
              Activity Log
            </Typography>
            <Divider />
            <List>
              <ListItem>
                <ListItemText
                  primary="Logged in from Chrome on Windows"
                  secondary="2 hours ago"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Uploaded a PDF file"
                  secondary="1 day ago"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Generated a quiz"
                  secondary="3 days ago"
                />
              </ListItem>
            </List>
          </Box>
        </CardContent>
      </ProfileCard>
    );
  } else if (simpleUser) {
    return (
      <ProfileCard>
        <CardContent>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <ProfileAvatar alt={simpleUser.name} src={simpleUser.photoURL} />
          <Typography variant="h5" component="div" gutterBottom>
            {simpleUser.displayName}
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {simpleUser.email}
          </Typography>

          <Box mt={2}>
            <ProfileButton
              variant="outlined"
              onClick={handleSignoutWithNavigateJWT}
            >
              Switch Account
            </ProfileButton>
          </Box>

          <Box mt={2}>
            <SignOutButton variant="outlined" onClick={handleSignOutClick}>
              Sign Out
            </SignOutButton>
          </Box>

          {/* Account Details Section */}
          <Box mt={4} textAlign="left">
            <Typography variant="h6" gutterBottom>
              Account Details
            </Typography>
            <Divider />
            <List>
              <ListItem>
                <ListItemText
                  primary="Full Name"
                  secondary={simpleUser.displayName}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Email" secondary={simpleUser.email} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Phone"
                  secondary={simpleUser.phoneNumber || "N/A"}
                />
              </ListItem>
            </List>
          </Box>

          {/* Settings Section */}
          <Box mt={4} textAlign="left">
            <Typography variant="h6" gutterBottom>
              Settings
            </Typography>
            <Divider />
            <List>
              <ListItem button>
                <ListItemText primary="Change Password" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Notification Preferences" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Privacy Settings" />
              </ListItem>
            </List>
          </Box>

          {/* Activity Log Section */}
          <Box mt={4} textAlign="left">
            <Typography variant="h6" gutterBottom>
              Activity Log
            </Typography>
            <Divider />
            <List>
              <ListItem>
                <ListItemText
                  primary="Logged in from Chrome on Windows"
                  secondary="2 hours ago"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Uploaded a PDF file"
                  secondary="1 day ago"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Generated a quiz"
                  secondary="3 days ago"
                />
              </ListItem>
            </List>
          </Box>
        </CardContent>
      </ProfileCard>
    );
  } else {
    return null;
  }
};

export default Profile;
