import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  Avatar,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Star as StarIcon } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { dataSlice } from "../store/dataSlice";
import { useSelector } from "react-redux";
import { userSlice } from "../store/userSlice";

const LandingPage = () => {

  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.accessToken);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGetStarted = () => {

    if (user || accessToken) {
      navigate("/write-article");
    } else {
      navigate("/auth");
    }
  };

  useEffect(() => {
    dispatch(dataSlice.actions.setWhichPage("home-page"));
  }, []);

  return (
    <Container maxWidth="lg">
      {/* Hero Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "90vh",
          textAlign: "center",
          backgroundColor: "#f5f5f5",
          padding: "2rem",
        }}
      >
        <Typography variant="h2" gutterBottom>
          Civil Engineering AI Assistant
        </Typography>
        <Typography variant="h5" gutterBottom>
          Methodology and results from you <br />
          The rest is on us
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleGetStarted}
          sx={{ mt: 4 }}
        >
          Get Started
        </Button>
      </Box>

      {/* Features Section */}
      <Box sx={{ padding: "4rem 0" }} hidden = {true}>
        <Typography variant="h4" gutterBottom textAlign="center">
          Features
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Scientific Writing
                </Typography>
                <Typography variant="body1">
                  Given your methodology and results and a list of Pdf files,
                  write a scientific paper.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Text Extraction
                </Typography>
                <Typography variant="body1">
                  Extract text from your PDF files with high accuracy.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Summarization
                </Typography>
                <Typography variant="body1">
                  Summarize lengthy documents into concise summaries.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Quiz Generation
                </Typography>
                <Typography variant="body1">
                  Generate quizzes based on the content of your PDFs.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* How It Works Section */}
      <Box sx={{ padding: "4rem 0", backgroundColor: "#f5f5f5" }} hidden={true}>
        <Typography variant="h4" gutterBottom textAlign="center">
          How It Works
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Step 1
                </Typography>
                <Typography variant="body1">
                  Upload your PDF files to our platform. You can upload multiple
                  files at once.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Step 2
                </Typography>
                <Typography variant="body1">
                  Our AI analyzes the content and extracts the necessary
                  information.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Step 3
                </Typography>
                <Typography variant="body1">
                  Download the results or use them directly on our platform.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Pricing Section */}
      <Box sx={{ padding: "4rem 0" }} hidden={true}>
        <Typography variant="h4" gutterBottom textAlign="center">
          Pricing
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Free Plan
                </Typography>
                <Typography variant="body1">
                  Basic features for personal use.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  $0/month
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Pro Plan
                </Typography>
                <Typography variant="body1">
                  Advanced features for professionals.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  $19.99/month
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Enterprise Plan
                </Typography>
                <Typography variant="body1">
                  All features for large teams and organizations.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Contact us for pricing
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Testimonials Section */}
      <Box sx={{ padding: "4rem 0", backgroundColor: "#f5f5f5" }} hidden={true}>
        <Typography variant="h4" gutterBottom textAlign="center">
          Testimonials
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Avatar sx={{ mr: 2 }}>A</Avatar>
                  <Typography variant="h6">Alice</Typography>
                </Box>
                <Typography variant="body1">
                  "This tool has revolutionized the way I handle PDF documents.
                  Highly recommended!"
                </Typography>
                <Box sx={{ display: "flex", mt: 2 }}>
                  <StarIcon color="primary" />
                  <StarIcon color="primary" />
                  <StarIcon color="primary" />
                  <StarIcon color="primary" />
                  <StarIcon color="primary" />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Avatar sx={{ mr: 2 }}>B</Avatar>
                  <Typography variant="h6">Bob</Typography>
                </Box>
                <Typography variant="body1">
                  "The AI summarization feature is a game-changer for my
                  research work."
                </Typography>
                <Box sx={{ display: "flex", mt: 2 }}>
                  <StarIcon color="primary" />
                  <StarIcon color="primary" />
                  <StarIcon color="primary" />
                  <StarIcon color="primary" />
                  <StarIcon color="primary" />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Avatar sx={{ mr: 2 }}>C</Avatar>
                  <Typography variant="h6">Charlie</Typography>
                </Box>
                <Typography variant="body1">
                  "Generating quizzes from PDFs has never been easier. Fantastic
                  tool!"
                </Typography>
                <Box sx={{ display: "flex", mt: 2 }}>
                  <StarIcon color="primary" />
                  <StarIcon color="primary" />
                  <StarIcon color="primary" />
                  <StarIcon color="primary" />
                  <StarIcon color="primary" />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Team Section */}
      <Box sx={{ padding: "4rem 0" }} hidden={true}>
        <Typography variant="h4" gutterBottom textAlign="center">
          Meet Our Team
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{ width: 100, height: 100, mb: 2 }}>JS</Avatar>
                  <Typography variant="h6">Jane Sally</Typography>
                  <Typography variant="body1">CEO & Co-Founder</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{ width: 100, height: 100, mb: 2 }}>JD</Avatar>
                  <Typography variant="h6">John Doe</Typography>
                  <Typography variant="body1">CTO & Co-Founder</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* FAQ Section */}
      <Box sx={{ padding: "4rem 0", backgroundColor: "#f5f5f5" }} hidden={true}>
        <Typography variant="h4" gutterBottom textAlign="center">
          Frequently Asked Questions
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  What is AI PDF Analyzer?
                </Typography>
                <Typography variant="body1">
                  AI PDF Analyzer is a tool that uses advanced AI technology to
                  analyze and extract information from PDF files.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  How does it work?
                </Typography>
                <Typography variant="body1">
                  Simply upload your PDF files, and our AI will analyze the
                  content and provide the necessary information.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Is there a free trial available?
                </Typography>
                <Typography variant="body1">
                  Yes, we offer a free plan with basic features for personal
                  use.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  How can I contact support?
                </Typography>
                <Typography variant="body1">
                  You can reach out to our support team by clicking the "Contact
                  Support" button in the Contact Us section.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Newsletter Signup Section */}
      <Box sx={{ padding: "4rem 0" }} hidden={true}>
        <Typography variant="h4" gutterBottom textAlign="center">
          Stay Updated
        </Typography>
        <Typography variant="body1" textAlign="center" gutterBottom>
          Subscribe to our newsletter to get the latest updates and news.
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <TextField label="Email Address" variant="outlined" sx={{ mr: 2 }} />
          <Button variant="contained" color="primary" size="large">
            Subscribe
          </Button>
        </Box>
      </Box>

      {/* Contact Us Section */}
      <Box sx={{ padding: "4rem 0" }}>
        <Typography variant="h4" gutterBottom textAlign="center">
          Contact Us
        </Typography>
        <Typography variant="body1" textAlign="center" gutterBottom>
          Have questions or need support? Reach out to us!
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}
            onClick={()=> window.open("mailto:vahidasgharee@gmail.com")}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            >
            Contact
          </Button>
        </Box>
      </Box>

      {/* Footer Section */}
      <Box
        sx={{
          padding: "2rem 0",
          textAlign: "center",
          backgroundColor: "#282c34",
          color: "#fff",
        }}
      >
        <Typography variant="body1">
          &copy; {new Date().getFullYear()} CE AI Assistant. All rights
          reserved.
        </Typography>
      </Box>
    </Container>
  );
};

export default LandingPage;
