import {
    Card,
    CardActionArea,
    CardContent,
    IconButton,
    Grid,
    Collapse,
    Menu,
    MenuItem,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
  } from "@mui/material";

import { handleSaveNewProject } from "../utils/Endpoints";

export default function CreateNewProject(
    { 
        newProjectDialogOpen, 
        setNewProjectDialogOpen,
        newProjectName, 
        setNewProjectName, 
        dispatch, 
        dataSlice, 
        accessToken, 
        refetchGetAllProjects, 
        userSlice, 
        refetchGetAllPdfFiles 
    }
    ) {


    return (

        <Dialog
            open={newProjectDialogOpen}
            onClose = {() => setNewProjectDialogOpen(false)}
            >
            <DialogTitle>Create New Project</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Project Name"
                type="text"
                fullWidth
                value={newProjectName}
                onChange={(e) => setNewProjectName(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setNewProjectDialogOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={
                  () =>
                    handleSaveNewProject(
                      dispatch,
                      dataSlice,
                      newProjectName,
                      accessToken,
                      refetchGetAllProjects,
                      setNewProjectName,
                      setNewProjectDialogOpen,
                      userSlice,
                      refetchGetAllPdfFiles
                    )
                }
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

    )
}

    