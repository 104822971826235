import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export default function ShowToast(errorValue, toastType = "error") {

    if (toastType === "error") {
      toast.error(errorValue, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.success(errorValue, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };