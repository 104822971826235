import React from "react";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import { handleSignOut } from "../utils/Auth";
import { useNavigate } from "react-router-dom";
import { dataSlice } from "../store/dataSlice";
import { useDispatch } from "react-redux";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 8,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgb(0, 0, 0, 0.1) 0px 0px 0px 1px, rgb(0, 0, 0, 0.1) 0px 4px 6px -1px, rgb(0, 0, 0, 0.1) 0px 1px 0px",
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
  "& .MuiMenuItem-root": {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const CustomMenu = ({ anchorEl, handleMenuClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleProfileClick = () => {
    handleMenuClose();
    dispatch(dataSlice.actions.setProfileModalOpen(true));
  };

  const handleSignOutModified = () => {
    navigate("/auth");
    handleSignOut();
  };

  return (
    <StyledMenu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfileClick}>
        <ListItemIcon>
          <PersonIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Profile</Typography>
      </MenuItem>

      <MenuItem onClick={handleSignOutModified}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Sign Out</Typography>
      </MenuItem>
    </StyledMenu>
  );
};

export default CustomMenu;
