import { signOut } from "firebase/auth";

import { auth, googleProvider } from "./sdk";
import { signInWithPopup } from "firebase/auth";
import axios from "axios";

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const handleGoogleSignIn = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;

    const idToken = await user.getIdToken();

    const backend_response = await axios.post(
      REACT_APP_BACKEND_URL + "api/auth/register-firebase-google/",
      {
        uid: user.uid,
        email: user.email,
        name: user.displayName,
        phone: user.phoneNumber,
      },
      {
        headers: {
          "Authorization": `Bearer ${idToken}`,
        },
      }
    );
  } catch (error) {
    console.error("Error signing in with Google: ", error);
  }
};

export const handleSignOut = async () => {
  try {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    for (let key in localStorage) {
      if (key.startsWith("multi-pdf") || key.startsWith("single-pdf")) {
        localStorage.removeItem(key);
      }
    }

    await signOut(auth);
    window.location.reload();

  } catch (error) {
    console.error("Error signing out: ", error);
    // alert(`Error signing out: ${error.message}`);
  }
};
