import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import ArticleIcon from "@mui/icons-material/Article";
import Tooltip from "@mui/material/Tooltip";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import CustomMenu from "./CustomMenu";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import "../css/customScrollbar.css";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import HomeIcon from "@mui/icons-material/Home";

import { dataSlice } from "../store/dataSlice";
import Profile from "./Profile";
import { useSelector } from "react-redux";

import MenuComponent from "./MenuComponent";

export default function AppTopPane({ user }) {
  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// variables ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  const accessToken = useSelector((state) => state.user.accessToken);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const profileModalOpen = useSelector((state) => state.data.profileModalOpen);
  const dispatch = useDispatch();
  const whichPage = useSelector((state) => state.data.whichPage);

  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// functions ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);

    dispatch(dataSlice.actions.setProfileModalOpen(false));
  };

  const customNavigate = (path) => {
    if (user || accessToken) {
      navigate(path);
    } else {
      navigate("/auth");
    }
  };

  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// useEffects ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar sx={{ paddingLeft: "10px !important" }}>

          <Box sx={{ display: { xs: "none", md: "flex" }, marginRight: 2 }}>
              <Tooltip title="Home Page">
                <Button
                  onClick={() => navigate("/")}
                  variant="contained"
                  sx={{
                    backgroundColor: "#4285F4",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#357ae8",
                    },
                    textTransform: "none",
                    borderRadius: "50%",
                    width: 40,
                    height: 40,
                    minWidth: 0,
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <HomeIcon
                    sx={{
                      fontSize: 24,
                    }}
                  />
                </Button>
              </Tooltip>
            </Box>

            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 2,
              }}
            >

              {whichPage === "write-article" ? (
                <MenuComponent />
              ) : (
                <Button
                  onClick={() => customNavigate("/write-article")}
                  variant="contained"
                  sx={{
                    backgroundColor: "#4285F4",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#357ae8",
                    },
                    textTransform: "none",
                  }}
                  startIcon={<ArticleIcon />}
                >
                  Writing Assistant
                </Button>
              )}
            </Box>



            <Box sx={{ flexGrow: 1 }} />

            

            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {user || accessToken ? (
                <>
                  <Tooltip title="Account Settings">
                    <Button
                      onClick={handleMenuOpen}
                      variant="contained"
                      sx={{
                        backgroundColor: "#4285F4",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#357ae8",
                        },
                        textTransform: "none",
                        borderRadius: "50%",
                        width: 40,
                        height: 40,
                        minWidth: 0,
                        padding: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <AccountCircleIcon
                        sx={{
                          fontSize: 24,
                        }}
                      />
                    </Button>
                  </Tooltip>
                  <div>
                    <CustomMenu
                      anchorEl={anchorEl}
                      handleMenuClose={handleMenuClose}
                    />
                  </div>
                </>
              ) : (
                <Tooltip title="SignIn / SignUp">
                  <Button
                    onClick={() => navigate("/auth")}
                    variant="contained"
                    sx={{
                      backgroundColor: "#4285F4",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#357ae8",
                      },
                      textTransform: "none",
                      borderRadius: "50%",
                      width: 40,
                      height: 40,
                      minWidth: 0,
                      padding: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PersonAddIcon
                      sx={{
                        fontSize: 24,
                      }}
                    />
                  </Button>
                </Tooltip>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <Modal open={profileModalOpen} onClose={handleMenuClose}>
        <>
          <Profile handleClose={handleMenuClose} />
        </>
      </Modal>
    </>
  );
}
