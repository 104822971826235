import { createSlice } from "@reduxjs/toolkit";
import { ref } from "yup";

const initialState = {
  methodology: "",
  researchGap: "",
  result: "",
  litRevOneByOne: "",
  litRevPage: "",
  firstParagraph: "",
  secondParagraph: "",
  thirdParagraph: "",
  fourthParagraph: "",
  fifthParagraph: "",
  introductionPage: "",
  conclusionPage: "",
  abstractPage: "",
  highlights: "",
  finalPaper: "",

  litRevOneByOneGeneratingStatus: "idle",
  litRevPageGeneratingStatus: "idle",
  firstParagraphGeneratingStatus: "idle",
  secondParagraphGeneratingStatus: "idle",
  thirdParagraphGeneratingStatus: "idle",
  fourthParagraphGeneratingStatus: "idle",
  fifthParagraphGeneratingStatus: "idle",
  introductionPageGeneratingStatus: "idle",
  conclusionPageGeneratingStatus: "idle",
  abstractPageGeneratingStatus: "idle",
  highlightsGeneratingStatus: "idle",
};

export const articleSlice = createSlice({
  name: "article",
  initialState: initialState,
  reducers: {
    setMethodology: (state, action) => {
      state.methodology = action.payload;
    },
    setResearchGap: (state, action) => {
      state.researchGap = action.payload;
    },
    setLitRevOneByOne: (state, action) => {
      state.litRevOneByOne = action.payload;
    },
    setResult: (state, action) => {
      state.result = action.payload;
    },
    setLitRevPage: (state, action) => {
      state.litRevPage = action.payload;
    },
    setFirstParagraph: (state, action) => {
      state.firstParagraph = action.payload;
    },
    setSecondParagraph: (state, action) => {
      state.secondParagraph = action.payload;
    },
    setThirdParagraph: (state, action) => {
      state.thirdParagraph = action.payload;
    },
    setFourthParagraph: (state, action) => {
      state.fourthParagraph = action.payload;
    },
    setFifthParagraph: (state, action) => {
      state.fifthParagraph = action.payload;
    },
    setIntroductionPage: (state, action) => {
      state.introductionPage = action.payload;
    },
    setConclusionPage: (state, action) => {
      state.conclusionPage = action.payload;
    },
    setAbstractPage: (state, action) => {
      state.abstractPage = action.payload;
    },
    setHighlights: (state, action) => {
      state.highlights = action.payload;
    },
    setFinalPaper: (state, action) => {
      state.finalPaper = action.payload;
    },
    setLitRevOneByOneGeneratingStatus: (state, action) => {
      state.litRevOneByOneGeneratingStatus = action.payload;
    },
    setLitRevPageGeneratingStatus: (state, action) => {
      state.litRevPageGeneratingStatus = action.payload;
    },
    setFirstParagraphGeneratingStatus: (state, action) => {
      state.firstParagraphGeneratingStatus = action.payload;
    },
    setSecondParagraphGeneratingStatus: (state, action) => {
      state.secondParagraphGeneratingStatus = action.payload;
    },
    setThirdParagraphGeneratingStatus: (state, action) => {
      state.thirdParagraphGeneratingStatus = action.payload;
    },
    setFourthParagraphGeneratingStatus: (state, action) => {
      state.fourthParagraphGeneratingStatus = action.payload;
    },
    setFifthParagraphGeneratingStatus: (state, action) => {
      state.fifthParagraphGeneratingStatus = action.payload;
    },
    setIntroductionPageGeneratingStatus: (state, action) => {
      state.introductionPageGeneratingStatus = action.payload;
    },
    setConclusionPageGeneratingStatus: (state, action) => {
      state.conclusionPageGeneratingStatus = action.payload;
    },
    setAbstractPageGeneratingStatus: (state, action) => {
      state.abstractPageGeneratingStatus = action.payload;
    },
    setHighlightsGeneratingStatus: (state, action) => {
      state.highlightsGeneratingStatus = action.payload;
    },
  },
});

