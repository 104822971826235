import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  accessToken: "",
  refreshToken: "",
  projects: [],
  selectedProjectName: localStorage.getItem("selectedProjectName") || "",
  jobInProgress: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload; // Set the user
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload; // Set the access token
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload; // Set the refresh
    },

    setProjects: (state, action) => {
      state.projects = action.payload; // Set the projects
    },

    setSelectedProjectName: (state, action) => {
      state.selectedProjectName = action.payload; // Set the selected project name
      localStorage.setItem("selectedProjectName", action.payload);
    },
    setJobInProgress: (state, action) => {
      state.jobInProgress = action.payload; // Set the job on progress
    },
  },
});