import React, { useState, useCallback } from "react";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import Box from "@mui/material/Box";
import {
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  Grid,
  Collapse,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  DeleteOutlined,
  ExpandLessOutlined,
  ExpandMoreOutlined,
} from "@mui/icons-material";

import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import axios from "axios";
import { Button } from "@mui/material";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import Modal from "@mui/material/Modal";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dataSlice, setChatHistory, setPdfs } from "../store/dataSlice";
import PdfUpload from "../components/PdfUpload";

import "../css/customScrollbar.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import ChatBoxWriteArticle from "../components/ChatBoxWriteArticle";
import ShowToast from "../components/toast";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "../utils/Auth";
import { handleDeletePdf } from "../utils/Endpoints";

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
// Set the workerSrc property
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

export function PdfDisplay() {
  const llmResponseLoading = useSelector(
    (state) => state.data.llmResponseLoading
  );
  const user = useSelector((state) => state.user.user);

  const [expandedIndex, setExpandedIndex] = useState(null);
  const availablePdfsSizes = useSelector((state) => state.data.pdfSizes);

  const accessToken = useSelector((state) => state.user.accessToken);
  const selectedPdfFileNames = useSelector(
    (state) => state.data.selectedPdfFileNames
  );

  const dispatch = useDispatch();
  const availablePdfs = useSelector((state) => state.data.pdfs);
  const availableQuestions = useSelector((state) => state.data.questions);
  const chatHistory = useSelector((state) => state.data.chatHistory);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFilesChanged, setSelectedFilesChanged] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const navigate = useNavigate();
  const pdfDetails = useSelector((state) => state.data.pdfsDetails);
  const refetch = useSelector((state) => state.data.refetchGetAllPdfFiles);

  const litRevOneByOne = useSelector((state) => state.article.litRevOneByOne);
  const litRevPage = useSelector((state) => state.article.litRevPage);
  const firstParagraph = useSelector((state) => state.article.firstParagraph);
  const secondParagraph = useSelector((state) => state.article.secondParagraph);
  const thirdParagraph = useSelector((state) => state.article.thirdParagraph);
  const fourthParagraph = useSelector((state) => state.article.fourthParagraph);
  const fifthParagraph = useSelector((state) => state.article.fifthParagraph);
  const introductionPage = useSelector(
    (state) => state.article.introductionPage
  );
  const conclusionPage = useSelector((state) => state.article.conclusionPage);
  const abstractPage = useSelector((state) => state.article.abstractPage);
  const highlights = useSelector((state) => state.article.highlights);

  const methodology = useSelector((state) => state.article.methodology);
  const results = useSelector((state) => state.article.result);
  const jobInProgress = useSelector((state) => state.user.jobInProgress);
  const finalPaper = useSelector((state) => state.article.finalPaper);
  const refetchGetAllProjects = useSelector(
    (state) => state.data.refetchGetAllProjects
  );

  const selectedProjectName = useSelector(
    (state) => state.user.selectedProjectName
  );

  const handleItemClick = (index, item) => {
    return;
    if (selectedPdfFileNames.includes(item)) {
      const newSelectedPdfFileNames = selectedPdfFileNames.filter(
        (i) => i !== item
      );
      if (newSelectedPdfFileNames.length > 0) {
        setSelectedFilesChanged(true);
      } else {
        setSelectedFilesChanged(false);
      }
      dispatch(
        dataSlice.actions.setSelectedPdfFileNames(newSelectedPdfFileNames)
      );
    } else {
      setSelectedFilesChanged(true);
      const newSelectedPdfFileNames = [...selectedPdfFileNames, item];
      dispatch(
        dataSlice.actions.setSelectedPdfFileNames(newSelectedPdfFileNames)
      );
    }
  };

  const handleExpandClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const viewPdfFile = async (fileName) => {
    if (!user && !accessToken) return;
    if (!selectedProjectName || selectedProjectName === "Select Project") {
      ShowToast("Please select a project first.");
      return;
    }

    let idToken = null;
    if (user) {
      idToken = await user.getIdToken();
    } else {
      idToken = accessToken;
    }

    try {
      let tempValue = {
        data: null,
      };

      // store it in local storage
      if (pdfDetails[fileName] === undefined || pdfDetails[fileName] === null) {
        const response = await axios.get(
          `${REACT_APP_BACKEND_URL}api/pdf/get-single-pdf-file/${fileName}/`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
            params: {
              project_name: selectedProjectName,
            },
            responseType: "blob", // Important for downloading files
          }
        );

        const reader = new FileReader();
        reader.readAsDataURL(response.data);

        reader.onloadend = () => {
          const base64data = reader.result;

          tempValue = {
            data: base64data,
          };

          dispatch(
            dataSlice.actions.setPdfsDetails({
              ...pdfDetails,
              [fileName]: {
                data: tempValue["data"],
              },
            })
          );

          setPdfUrl(base64data);
          setModalOpen(true); // Open the modal
        };
      } else {
        if (pdfDetails[fileName]["url"] === null) {
          const response = await axios.get(
            `${REACT_APP_BACKEND_URL}api/pdf/get-single-pdf-file/${fileName}/`,
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
              responseType: "blob", // Important for downloading files
            }
          );

          const reader = new FileReader();
          reader.readAsDataURL(response.data);

          reader.onloadend = () => {
            const base64data = reader.result;
            tempValue["data"] = base64data;

            dispatch(
              dataSlice.actions.setPdfsDetails({
                ...pdfDetails,
                [fileName]: {
                  data: tempValue["data"],
                },
              })
            );

            setPdfUrl(base64data);
            setModalOpen(true); // Open the modal
          };
        } else {
          setPdfUrl(pdfDetails[fileName]["data"]);
          setModalOpen(true); // Open the modal
        }
      }
    } catch (error) {
      console.error("Error viewing PDF file:", error);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        overflowY: "auto",
        marginTop: 1,
        overflowX: "hidden", // Prevent horizontal overflow
        padding: 1,
        marginBottom: 2,
      }}
      className="custom-scrollbar"
    >
      {availablePdfs.map((item, index) => (
        <Card
          key={index}
          sx={{
            bgcolor: selectedPdfFileNames.includes(item) ? "#f0f0f0" : "white",
            transition: "transform 0.2s, box-shadow 0.2s",
            "&:hover": {
              transform: "scale(1.02)",
              boxShadow: "0 4px 2px rgba(0,0,0,0.1)",
            },
            marginBottom: 1,
          }}
          onClick={() => handleItemClick(index, item)}
        >
          <CardActionArea disableRipple disableTouchRipple>
            <CardContent
              sx={{
                backgroundColor: jobInProgress
                  ? "rgba(0, 0, 0, 0.1)"
                  : "inherit", // Gray out the box

                paddingTop: 1,
                paddingBottom: 1,
                "&:last-child": {
                  paddingBottom: 1,
                },
              }}
            >
              <Grid container spacing={2} alignItems="center">

                {/* <Grid item xs={2} container justifyContent="center">
                  <Tooltip title="View PDF" arrow>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        viewPdfFile(item);
                      }}
                      sx={{
                        color: "#4285F4",
                        backgroundColor: "#e8eaf6",
                        "&:hover": {
                          backgroundColor: "#c5cae9",
                        },
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </Grid> */}


                <Grid item xs={10}>
                  <Typography variant="body1" sx={{ fontSize: "14px" }} noWrap>
                    {item}
                  </Typography>
                </Grid>


                <Grid item xs={2}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeletePdf(
                        dispatch,
                        user,
                        accessToken,
                        item,
                        refetch,
                        selectedPdfFileNames,
                        dataSlice.actions.setSelectedPdfFileNames,
                        selectedProjectName
                      );
                    }}
                    sx={{
                      color: "#f44336",
                      backgroundColor: "#ffebee",
                      "&:hover": {
                        backgroundColor: "#ffcdd2",
                      },
                    }}
                  >
                    <DeleteOutlined />
                  </IconButton>
                </Grid>

                {/* <Grid item xs={2} container justifyContent="center">
                  <Tooltip
                    title={
                      selectedPdfFileNames.includes(item)
                        ? "Deselect"
                        : "Select"
                    }
                    arrow
                  >
                    <IconButton
                      sx={{
                        color: selectedPdfFileNames.includes(item)
                          ? "#3f51b5"
                          : "#ccc",
                      }}
                    >
                      {selectedPdfFileNames.includes(item) ? (
                        <CheckCircleIcon />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Grid> */}
              </Grid>
            </CardContent>
            <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
              <CardContent>
                {availablePdfsSizes && availablePdfsSizes[index] && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "12px" }}
                          noWrap
                        >
                          Size:{" "}
                          {availablePdfsSizes[index] < 1
                            ? "less than 1 MB"
                            : `${Number(
                                availablePdfsSizes[index].toFixed(2)
                              )} MB`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "12px" }}
                          noWrap
                        >
                          {/* Number of pages:{" "} */}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </CardContent>
            </Collapse>
          </CardActionArea>
        </Card>
      ))}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            padding: 2,
            borderRadius: 1,
            boxShadow: 24,
            maxWidth: "80%",

            maxHeight: "80%",
            overflow: "auto",
          }}
        >
          {pdfUrl && (
            <Document
              file={pdfUrl}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  className="pdf-page"
                />
              ))}
            </Document>
          )}
        </Box>
      </Modal>
    </Box>
  );
}
