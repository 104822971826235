import React, { useState, useRef } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { dataSlice } from "../store/dataSlice";
import ShowToast from "../components/toast";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import pdfToText from 'react-pdftotext'


const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        h4: {
          fontSize: "1rem",
          fontWeight: 600,
          color: "#2c3e50",

          paddingBottom: "8px",
          marginBottom: "16px",
          textAlign: "center", // Center the text
          // fontFamily: "inherit", // Use the default MUI font family
        },
      },
    },
  },
});

const ProjectName = styled("span")({
  fontSize: "1.5rem",
  fontWeight: "bold",
  color: "#3498db",
});

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const PdfUpload = () => {
  //////////////////////////////////////////////
  /////////////// states ///////////////////////
  //////////////////////////////////////////////
  const availablePdfs = useSelector((state) => state.data.pdfs);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.accessToken);
  const selectedProjectName = useSelector(
    (state) => state.user.selectedProjectName
  );

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);

    // Check if all selected files are PDFs
    const areAllPDFs = files.every(file => file.type === 'application/pdf');

    if (!areAllPDFs) {
        ShowToast("Only pdf files are supported at this moment.")
        return
    }
    
    if (!files.length) {
      console.log("No files selected");
      return;
    }
  
    setUploading(true);
    
    // Validate each file
    const validFiles = files.filter(file => {
      if (!file.type || file.type !== 'application/pdf') {
        console.warn(`File "${file.name}" is not a valid PDF`);
        return false;
      }
      if (file.size === 0) {
        console.warn(`File "${file.name}" is empty`);
        return false;
      }
      return true;
    });
  
    if (validFiles.length === 0) {
      console.error("No valid PDF files to upload");
      setUploading(false);
      return;
    }

    // Convert PDFs to text Files
    const textFiles = await Promise.all(validFiles.map(async (file) => {
      try {
        const text = await pdfToText(file);
        
        // Return the text file object
        return new File([text], file.name.replace(/\.pdf$/, '.txt'), { type: 'text/plain' });

      } catch (error) {
        console.error(`Failed to convert ${file.name} to text:`, error);
        return null;
      }
    }));

    try {
      // Upload files one by one and track progress
      try {
        await handleUpload(textFiles);
      } catch (error) {
        console.error(`Failed to upload`, error);
        // Continue with next file instead of stopping completely
      }

    } catch (error) {
      console.error("Upload process failed:", error);
    } finally {
      setUploading(false);
      // Clear the input value to ensure onChange fires even if same file is selected
      event.target.value = '';
    }
    
    setSelectedFiles(textFiles);
  };

  //////////////////////////////////////////////
  /////////////// functions ////////////////////
  //////////////////////////////////////////////

  const handleUpload = async (selectedFiles) => {
    if (!user && !accessToken) {
      console.log("No files selected");
      return;
    }

    if (!selectedProjectName) {
      ShowToast("Please select a project first");
      return;
    }

    let idToken = null;
    if (user) {
      idToken = await user.getIdToken();
    } else {
      idToken = accessToken;
    }

    setUploading(true);
    // const idToken = await user.getIdToken();

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      console.log(selectedFiles[i]);
      formData.append("files", selectedFiles[i]);
    }
    formData.append("project_name", selectedProjectName);

    axios
      .post(REACT_APP_BACKEND_URL + "api/pdf/upload/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        ShowToast("Files uploaded successfully!", "success");
        // toast.success("Files uploaded successfully!");
      })
      .catch((error) => {
        // toast.error("Error uploading files.");
        console.error("Error uploading files:", error);
      })
      .finally(() => {
        setSelectedFiles([]);
        setUploading(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        getAllPdfFiles();
      });
  };

  const getAllPdfFiles = async () => {

    if (!user && !accessToken) return;
    if (!selectedProjectName) {
      return;
    }
    let idToken = null;
    if (user) {
      idToken = await user.getIdToken();
    } else {
      idToken = accessToken;
    }

    try {
      const response = await axios.get(
        REACT_APP_BACKEND_URL + "api/pdf/getallpdfs/",
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
          params: {
            project_name: selectedProjectName,
          },
        }
      );
      if (availablePdfs !== response.data.files) {
        dispatch(dataSlice.actions.setPdfs(response.data.files)); // Use the action creator
        dispatch(dataSlice.actions.setPdfSizes(response.data.sizes)); // Use the action creator

        dispatch(dataSlice.actions.setSelectedPdfFileNames(response.data.files)); // Use the action creator
      }
    } catch (error) {
      console.error("Error getting all PDF files:", error);
    }
  };

  //////////////////////////////////////////////
  /////////////// useEffect ////////////////////
  //////////////////////////////////////////////
  return (
    <Box
      sx={{
        width: "100%",
        margin: "0 auto",
        borderRadius: 1,
      }}
    >
      <ThemeProvider theme={theme}>
        <Typography variant="h4" component="h2" gutterBottom>
          {selectedProjectName ? (
            <>
              Project: <ProjectName>{selectedProjectName}</ProjectName>
            </>
          ) : (
            "No Project Selected"
          )}
        </Typography>
      </ThemeProvider>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <input
          id="pdf-upload-input"
          type="file"
          accept=".pdf,application/pdf"
          multiple
          style={{ display: 'none' }}
          onChange={handleFileChange}
          disabled={(!user && !accessToken) || uploading}
        />
        <label htmlFor="pdf-upload-input">
          <Button
            variant="contained"
            component="span"
            sx={{
              backgroundColor: '#4285F4',
              '&:disabled': {
                backgroundColor: '#cccccc',
              }
            }}
            disabled={(!user && !accessToken) || uploading}
          >
            {uploading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Upload PDFs'
            )}
          </Button>
        </label>
      </Box>
    </Box>
  );
};

export default PdfUpload;