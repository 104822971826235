import "./App.css";
import AppTopPane from "./components/AppTopPane";
import MultiplePdf from "./pages/MultiplePdf";
import SinglePdf from "./pages/SinglePdf";
import { Routes, Route, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { signOut } from "firebase/auth";

import { onAuthStateChanged } from "firebase/auth";
import { auth, googleProvider } from "./utils/sdk";
import { signInWithPopup } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { dataSlice } from "./store/dataSlice";
import { userSlice } from "./store/userSlice";
import Profile from "./components/Profile";
import axios from "axios";
import LandingPage from "./pages/LandingPage";
import WriteArticlePage from "./pages/WriteArticlePage";
import AuthPage from "./pages/AuthPage";
import ResendConfirmationEmail from "./ResendConfirmationEmail";
import { handleGoogleSignIn, handleSignOut } from "./utils/Auth";
const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function App() {
  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// variables ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.accessToken);
  const availablePdfs = useSelector((state) => state.data.pdfs);
  const availablePdfsSizes = useSelector((state) => state.data.pdfSizes);
  const selectedProjectName = useSelector(
    (state) => state.user.selectedProjectName
  );
  const refetchGetAllPdfFiles = useSelector(
    (state) => state.data.refetchGetAllPdfFiles
  );
  const projects = useSelector((state) => state.user.projects);

  const refetchGetAllProjects = useSelector(
    (state) => state.data.refetchGetAllProjects
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// functions ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////

  const getAllProjects = async () => {
    if (!user && !accessToken) {
      return;
    }

    let idToken = null;
    if (user) {
      idToken = await user.getIdToken();
    } else {
      idToken = accessToken;
    }

    try {
      const response = await axios.get(
        REACT_APP_BACKEND_URL + "api/pdf/project/",
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      const sortedData = response.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      dispatch(userSlice.actions.setProjects(sortedData));
    } catch (error) {
      if (error && error.response && error.response.status === 403) {
        navigate("/auth");
        handleSignOut();
      } else {
        console.error("Error getting all projects:", error);
      }
    }
  };

  const getAllPdfFiles = async () => {
    if (!user && !accessToken) {
      return;
    }
    if (!selectedProjectName) {
      return;
    }

    let idToken = null;
    if (user) {
      idToken = await user.getIdToken();
    } else {
      idToken = accessToken;
    }

    try {
      const response = await axios.get(
        REACT_APP_BACKEND_URL + "api/pdf/getallpdfs/",
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
          params: {
            project_name: selectedProjectName,
          },
        }
      );
      if (availablePdfs !== response.data.files.sort()) {
        dispatch(dataSlice.actions.setPdfs(response.data.files.sort())); // Use the action creator
        dispatch(dataSlice.actions.setPdfSizes(response.data.sizes));
      }
    } catch (error) {
      if (error && error.response && error.response.status === 403) {
        navigate("/auth");
        handleSignOut();
      } else if (error && error.response === "Project not found" && error.response.status === 404) {
        dispatch(userSlice.actions.setSelectedProjectName(""));
      } else {
        console.error("Error viewing PDF file:", error);
      }
      console.error("Error getting all PDF files:", error);
    }
  };

  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// useEffects ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (user || accessToken) {
      getAllProjects();
    }
  }, [refetchGetAllProjects, user, accessToken]);

  useEffect(() => {
    if (user || accessToken) {
      getAllPdfFiles();
    }
  }, [refetchGetAllPdfFiles, user, accessToken]);

  useEffect(() => {
    // Function to load access token from localStorage

    // TODO: accessToken and refreshToken should not be stored in localStorage
    const loadAccessToken = () => {
      const token = localStorage.getItem("accessToken");
      dispatch(userSlice.actions.setAccessToken(token));
    };

    // Load access token when the component mounts
    loadAccessToken();

    // Add an event listener to detect changes in localStorage
    window.addEventListener("storage", loadAccessToken);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("storage", loadAccessToken);
    };
  }, []);

  useEffect(() => {
    // Function to load access token from localStorage
    const loadRefreshToken = () => {
      const token = localStorage.getItem("refreshToken");
      dispatch(userSlice.actions.setRefreshToken(token));
    };

    // Load access token when the component mounts
    loadRefreshToken();

    // Add an event listener to detect changes in localStorage
    window.addEventListener("storage", loadRefreshToken);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("storage", loadRefreshToken);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(userSlice.actions.setUser(user));
      } else {
        dispatch(userSlice.actions.setUser(null));
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <>

      <AppTopPane
        user={user}
        handleSignOut={handleSignOut}
        handleGoogleSignIn={handleGoogleSignIn}
      />

    <Routes>
      <Route
        path="/"
        element={
          <LandingPage />
        }
      />
      <Route
        path="/single-pdf"
        element={
          <>
            {(user || accessToken) && <SinglePdf />}
          </>
        }
      />
      <Route
        path="/multiple-pdf"
        element={
          <>
            {(user || accessToken) && <MultiplePdf />}
          </>
        }
      />
      <Route
        path="/write-article"
        element={
          <>
            {(user || accessToken) && <WriteArticlePage />}
          </>
        }
      />
      <Route
        path="/auth"
        element={
          <>
            <AuthPage />
          </>
        }
      />

      <Route
        path="/resend-confirmation-email"
        element={<ResendConfirmationEmail />}
      />
      <Route
        path="/profile"
        element={
          <>
            <Profile user={user} handleSignOut={handleSignOut} />
          </>
        }
      />
    </Routes>
    
    </>
  );
}

export default App; 

