import { configureStore } from "@reduxjs/toolkit";
import { dataSlice } from "./dataSlice";
import { userSlice } from "./userSlice";
import { articleSlice } from "./articleSlice";
const store = configureStore({
  reducer: {
    data: dataSlice.reducer,
    user: userSlice.reducer,
    article: articleSlice.reducer,
  },
});

export default store;
