import { createSlice } from "@reduxjs/toolkit";
import { ref } from "yup";

const initialState = {
  user: null,
  accessToken: "",
  refreshToken: "",
  pdfs: [],
  pdfSizes: [],
  pdfsDetails: {},
  chatHistory: [],
  llmResponseLoading: false,
  selectedPdfFileName: "",
  selectedPdfFileNames: [],

  questions: [
    "Introduction",
    "Conclusion",
    "Summary",
    "Discussion",
    "Result",
    "Methodology",
  ],
  userInput: "",
  profileModalOpen: false,
  abstract: "",
  introduction: "",
  methodology: "",
  researchGap: "",
  result: "",
  discussion: "",
  conclusion: "",
  reference: "",
  acknowledgement: "",
  appendix: "",
  title: "",
  finalPaper: "",
  checkEmail: false,
  whichPage: null,
  chatHistories: {},
  currentAIResponses: {},
  chatType: "",
  llmResponding: {},
  startNewChat: false,
  refetchGetAllPdfFiles: false,
  refetchGetAllProjects: false,
};

export const dataSlice = createSlice({
  name: "data",
  initialState: initialState,
  reducers: {
    setPdfs: (state, action) => {
      state.pdfs = action.payload; // Set the entire list of PDFs
    },
    setPdfSizes: (state, action) => {
      state.pdfSizes = action.payload; // Set the entire list of PDFs
    },
    setPdfsDetails: (state, action) => {
      state.pdfsDetails = action.payload; // Set the entire list of PDFs
    },
    setCheckEmail: (state, action) => {
      state.checkEmail = action.payload;
    },
    // chat history
    setChatHistory: (state, action) => {
      state.chatHistory = action.payload; // Set the entire list of chat history
    },
    setLlmResponseLoading: (state, action) => {
      state.llmResponseLoading = action.payload;
    },
    setSelectedPdfFileName: (state, action) => {
      state.selectedPdfFileName = action.payload;
    },
    setSelectedPdfFileNames: (state, action) => {
      state.selectedPdfFileNames = action.payload;
    },

    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    setUserInput: (state, action) => {
      state.userInput = action.payload;
    },
    setProfileModalOpen: (state, action) => {
      state.profileModalOpen = action.payload;
    },
    setAbstract: (state, action) => {
      state.abstract = action.payload;
    },
    setIntroduction: (state, action) => {
      state.introduction = action.payload;
    },
    setMethodology: (state, action) => {
      state.methodology = action.payload;
    },
    setResearchGap: (state, action) => {
      state.researchGap = action.payload;
    },
    setResult: (state, action) => {
      state.result = action.payload;
    },
    setDiscussion: (state, action) => {
      state.discussion = action.payload;
    },
    setConclusion: (state, action) => {
      state.conclusion = action.payload;
    },
    setReference: (state, action) => {
      state.reference = action.payload;
    },
    setAcknowledgement: (state, action) => {
      state.acknowledgement = action.payload;
    },
    setAppendix: (state, action) => {
      state.appendix = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setFinalPaper: (state, action) => {
      state.finalPaper = action.payload;
    },

    setWhichPage: (state, action) => {
      state.whichPage = action.payload;
    },
    setChatHistories: (state, action) => {
      state.chatHistories = action.payload;
    },
    setCurretChatResponses: (state, action) => {
      state.currentAIResponses = action.payload;
    },
    setChatType: (state, action) => {
      state.chatType = action.payload;
    },
    setLLMResponding: (state, action) => {
      state.llmResponding = action.payload;
    },
    setStartNewChat: (state, action) => {
      state.startNewChat = action.payload;
    },
    setRefetchGetAllPdfFiles: (state, action) => {
      state.refetchGetAllPdfFiles = action.payload;
    },
    setRefetchGetAllProjects: (state, action) => {
      state.refetchGetAllProjects = action.payload;
    },
  },
});

export const { setPdfs, setChatHistory, setLlmResponseLoading } =
  dataSlice.actions;
