import ShowToast from "../components/toast";
import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Avatar,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { AppBar, Toolbar, IconButton } from "@mui/material";
import {
  Refresh,
  Delete,
  SaveAlt,
  DeleteOutline,
  RefreshRounded,
  RefreshOutlined,
  RefreshSharp,
} from "@mui/icons-material";
import { MessageList } from "react-chat-elements";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useDispatch, useSelector } from "react-redux";
import "react-chat-elements/dist/main.css";
import { dataSlice } from "../store/dataSlice";
import { userSlice } from "../store/userSlice";
import "../css/ChatBox.css"; // Import the CSS file

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ChatBox = ({ chatEnabled }) => {
  ///////////////////////////////////////////////////////////////////
  ///////////////////// states //////////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  const dispatch = useDispatch();
  const userInput = useSelector((state) => state.data.userInput);
  const chatHistory = useSelector((state) => state.data.chatHistory);
  const abortControllerRef = useRef(null);

  const llmResponseLoading = useSelector(
    (state) => state.data.llmResponseLoading
  );
  const selectedPdfFileName = useSelector(
    (state) => state.data.selectedPdfFileName
  );
  const selectedPdfFileNames = useSelector(
    (state) => state.data.selectedPdfFileNames
  );
  const chatType = useSelector((state) => state.data.chatType);
  const startNewChat = useSelector((state) => state.data.startNewChat);
  const messageListRef = useRef(null);
  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.accessToken);
  const whichPage = useSelector((state) => state.data.whichPage);
  ///////////////////////////////////////////////////////////////////
  ///////////////////// functions ///////////////////////////////////
  ///////////////////////////////////////////////////////////////////

  const handleInputChange = (event) => {
    dispatch(dataSlice.actions.setUserInput(event.target.value));
  };

  const handleSendMessage = async () => {
    if (!user && !accessToken) {
      return;
    }
    if (chatType === "single-pdf") {
      if (!selectedPdfFileName) {
        return;
      }

      if (!userInput) {
        return;
      }

      if (llmResponseLoading) {
        return;
      }

      const newChatHistory = [
        ...chatHistory,
        {
          avatar: "images/human1.png",
          position: "right",
          type: "text",
          text: userInput,
          date: new Date().toISOString(),
        },
      ];
      dispatch(dataSlice.actions.setChatHistory(newChatHistory));

      dispatch(dataSlice.actions.setLlmResponseLoading(true));
      const chat_history_for_backend = chatHistory.map((message) => {
        return {
          role: message.avatar === "images/human1.png" ? "human" : "ai",
          content: message.text,
        };
      });
      let request = {
        file_name: selectedPdfFileName,
        question: userInput,
        chat_history: chat_history_for_backend,
      };
      let idToken = null;
      if (user) {
        idToken = await user.getIdToken();
      } else {
        idToken = accessToken;
      }

      let chatResponseReceieved = false;
      let ai_text = "";
      let updatedChatHistory = [];

      try {
        abortControllerRef.current = new AbortController();

        const response = await fetch(
          REACT_APP_BACKEND_URL + "api/pdf/chatpdf/",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
            signal: abortControllerRef.current.signal,
          }
        );

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          const chunk = decoder.decode(value, { stream: true });

          ai_text += chunk;
          updatedChatHistory = [
            ...newChatHistory,
            {
              avatar: "images/robot.png",
              position: "left",
              type: "text",
              text: ai_text,
              date: new Date().toISOString(),
            },
          ];
          dispatch(dataSlice.actions.setChatHistory(updatedChatHistory));
        }

        dispatch(dataSlice.actions.setLlmResponseLoading(false));
        chatResponseReceieved = true;

        if (chatResponseReceieved) {
          const finalPdfName = `single-pdf-${selectedPdfFileName}`;

          await axios
            .post(
              REACT_APP_BACKEND_URL + "api/pdf/chat-history/",
              {
                file_names: finalPdfName,
                human_text: userInput,
                ai_text: ai_text,
              },
              {
                headers: {
                  Authorization: `Bearer ${idToken}`,
                },
              }
            )
            .then(() => {
              let finalLocalStorageChatHistory = [];

              const updatedChatHistoryForLocalStorage = updatedChatHistory.map(
                (message) => {
                  return {
                    user:
                      message.avatar === "images/human1.png" ? "human" : "ai",
                    message: message.text,
                    date: message.date,
                  };
                }
              );
              finalLocalStorageChatHistory = [
                ...updatedChatHistoryForLocalStorage,
              ];

              localStorage.setItem(
                finalPdfName,
                JSON.stringify(finalLocalStorageChatHistory)
              );
            })
            .catch(() => {
              // remove the last two messages from chat history
              const updatedChatHistory = newChatHistory.slice(0, -2);
              dispatch(dataSlice.actions.setChatHistory(updatedChatHistory));
            });
        } else {
          // remove the last message from chat history
          const updatedChatHistory = newChatHistory.slice(0, -1);
          dispatch(dataSlice.actions.setChatHistory(updatedChatHistory));
        }

        dispatch(dataSlice.actions.setUserInput(""));
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          dispatch(dataSlice.actions.setLlmResponseLoading(false));
          // remove the last message from chat history
          const updatedChatHistory = newChatHistory.slice(0, -1);
          dispatch(dataSlice.actions.setChatHistory(updatedChatHistory));
        }
      }
    } else if (chatType === "multi-pdf") {
      if (selectedPdfFileNames.length === 0) {
        return;
      }

      if (!userInput) {
        return;
      }

      if (llmResponseLoading) {
        ShowToast("The AI is generating a response. Please wait.");
        console.log("the llm is generating a response. Please wait");
        return;
      }

      const newChatHistory = [
        ...chatHistory,
        {
          avatar: "images/human1.png",
          position: "right",
          type: "text",
          text: userInput,
          date: new Date().toISOString(),
        },
      ];
      dispatch(dataSlice.actions.setChatHistory(newChatHistory));

      dispatch(dataSlice.actions.setLlmResponseLoading(true));
      const chat_history_for_backend = chatHistory.map((message) => {
        return {
          role: message.avatar === "images/human1.png" ? "human" : "ai",
          content: message.text,
        };
      });
      let request = {
        file_names: selectedPdfFileNames,
        question: userInput,
        chat_history: chat_history_for_backend,
      };

      let idToken = null;
      if (user) {
        idToken = await user.getIdToken();
      } else {
        idToken = accessToken;
      }

      let chatResponseReceieved = false;
      let ai_text = "";
      let updatedChatHistory = [];

      try {
        abortControllerRef.current = new AbortController();

        const response = await fetch(
          REACT_APP_BACKEND_URL + "api/pdf/chatpdfs/",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
            signal: abortControllerRef.current.signal,
          }
        );

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          const chunk = decoder.decode(value, { stream: true });

          ai_text += chunk;
          updatedChatHistory = [
            ...newChatHistory,
            {
              avatar: "images/robot.png",
              position: "left",
              type: "text",
              text: ai_text,
              date: new Date().toISOString(),
            },
          ];
          dispatch(dataSlice.actions.setChatHistory(updatedChatHistory));
        }

        dispatch(dataSlice.actions.setLlmResponseLoading(false));
        chatResponseReceieved = true;

        if (chatResponseReceieved) {
          const copySelectedPdfFileNames = [...selectedPdfFileNames];
          const sortedSelectedPdfFileNames = copySelectedPdfFileNames.sort();
          const finalName = `multi-pdf-${sortedSelectedPdfFileNames.join("_")}`;

          await axios
            .post(
              REACT_APP_BACKEND_URL + "api/pdf/chat-history/",
              {
                file_names: finalName,
                human_text: userInput,
                ai_text: ai_text,
              },
              {
                headers: {
                  Authorization: `Bearer ${idToken}`,
                },
              }
            )
            .then(() => {
              let finalLocalStorageChatHistory = [];

              const updatedChatHistoryForLocalStorage = updatedChatHistory.map(
                (message) => {
                  return {
                    user:
                      message.avatar === "images/human1.png" ? "human" : "ai",
                    message: message.text,
                    date: message.date,
                  };
                }
              );
              finalLocalStorageChatHistory = [
                ...updatedChatHistoryForLocalStorage,
              ];

              localStorage.setItem(
                finalName,
                JSON.stringify(finalLocalStorageChatHistory)
              );
            })
            .catch(() => {
              // remove the last two messages from chat history
              const updatedChatHistory = newChatHistory.slice(0, -2);
              dispatch(dataSlice.actions.setChatHistory(updatedChatHistory));
            });
        } else {
          // remove the last message from chat history
          const updatedChatHistory = newChatHistory.slice(0, -1);
          dispatch(dataSlice.actions.setChatHistory(updatedChatHistory));
        }

        dispatch(dataSlice.actions.setUserInput(""));
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          dispatch(dataSlice.actions.setLlmResponseLoading(false));
          // remove the last message from chat history
          const updatedChatHistory = newChatHistory.slice(0, -1);
          dispatch(dataSlice.actions.setChatHistory(updatedChatHistory));
        }
      }
    } else {
      console.log("chatType not set");
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const scrollToBottom = () => {
    if (messageListRef && messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };
  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// useEffects ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  useEffect(() => {
    /// if there is any ongoing fetch request and the file names change,we cancel the fetch request
    if (llmResponseLoading) {
      dispatch(dataSlice.actions.setLlmResponseLoading(false));
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        console.log("abort");
      }
    }
  }, [selectedPdfFileName, startNewChat, whichPage]);
  return (
    <Box
      sx={{
        border: "1px solid #ccc",
        marginLeft: 1,
        height: "90vh",

        marginTop: 1,
        borderRadius: 1,
        backgroundColor: "#f9f9f9",
        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
      }}
    >
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        sx={{ backgroundColor: "#f0f0f0" }}
      >
        <Toolbar
          variant="dense"
          sx={{
            minHeight: "auto",
            padding: 0,
            justifyContent: "space-between", // Adjust to space-between
            border: "0px",
          }}
        >
          <Typography
            sx={{
              padding: "4px 8px",
              fontWeight: "bold",
            }}
          >
            {selectedPdfFileName !== null ? 1 : "No"} PDF file selected.
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Reset chat" arrow>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="reset"
                sx={{
                  padding: "4px",
                  marginRight: "4px",
                  marginLeft: "4px",
                  color: "#4285F4",
                  backgroundColor: "#d1eaff",
                  "&:hover": {
                    backgroundColor: "#bfdcff",
                  },
                }}
              >
                <Refresh sx={{ fontSize: "18px" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete chat" arrow>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="delete"
                sx={{
                  padding: "4px",
                  marginRight: "4px",
                  marginLeft: "4px",
                  color: "#f44336",
                  backgroundColor: "#ffebee",
                  "&:hover": {
                    backgroundColor: "#ffcdd2",
                  },
                }}
              >
                <DeleteOutline sx={{ fontSize: "18px" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Export chat" arrow>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="export"
                sx={{
                  padding: "4px",
                  marginRight: "4px",
                  marginLeft: "4px",
                  color: "#4caf50",
                  backgroundColor: "#d1ffdc",
                  "&:hover": {
                    backgroundColor: "#adffc1",
                  },
                }}
              >
                <SaveAlt sx={{ fontSize: "18px" }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        ref={messageListRef}
        sx={{
          height: "66vh",
          overflowY: "auto",
          padding: 2,
          paddingLeft: 1,
          paddingRight: 1,
          position: "relative",
          backgroundColor: "#fafafa",
        }}
      >
        <MessageList
          messageBoxStyles={{
            backgroundColor: "#fffff2",
            border: "1px solid #ccc",
            marginTop: 5,
            marginBottom: 5,
            marginLeft: 0,
            marginRight: 0,
            borderRadius: 1,
            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",

            padding: "10px", // Add padding for better spacing
            width: "100%", // Ensure full width
            boxSizing: "border-box", // Include padding in width calculation
          }}
          className="message-list"
          lockable={true}
          toBottomHeight={"100%"}
          dataSource={chatHistory.map((message) => ({
            ...message,
            text: message.text && (
              <ReactMarkdown
                className="message-text"
                rehypePlugins={[rehypeRaw]}
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                      <SyntaxHighlighter
                        style={materialDark}
                        language={match[1]}
                        PreTag="div"
                        {...props}
                      >
                        {String(children).replace(/\n$/, "")}
                      </SyntaxHighlighter>
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  },
                }}
              >
                {message.text}
              </ReactMarkdown>
            ),
            dateString: message.date
              ? new Date(message.date).toLocaleTimeString()
              : "",
          }))}
        />
        {llmResponseLoading && (
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: 2,
          borderTop: "1px solid #ccc",
          backgroundColor: "#fff",
          borderBottomLeftRadius: 1,
          borderBottomRightRadius: 1,
        }}
      >
        <TextField
          disabled={!chatEnabled}
          placeholder="Type your message..."
          multiline
          fullWidth
          rows={2}
          // maxRows={2}
          value={userInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          variant="outlined"
          sx={{ marginRight: 2 }}
        />

        <Button
          disabled={!chatEnabled || llmResponseLoading}
          variant="contained"
          color="primary"
          onClick={handleSendMessage}
          sx={{ height: "100%" }}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default ChatBox;
