import React, { useState, useCallback } from "react";
import axios from "axios";
import ChatBox from "../components/ChatBox";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dataSlice, setChatHistory, setPdfs } from "../store/dataSlice";
import PdfUpload from "../components/PdfUpload";
import { pdfjs } from "react-pdf";
import "../css/customScrollbar.css";
import {
  Box,
  Typography,
  CardActionArea,
  Card,
  CardContent,
  IconButton,
  Modal,
  Grid,
  Button,
  Collapse,
} from "@mui/material";
import {
  DeleteOutlined,
  ExpandLessOutlined,
  ExpandMoreOutlined,
} from "@mui/icons-material";
import {
  Visibility as VisibilityIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import { Document, Page } from "react-pdf";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "../utils/Auth";
import { handleDeletePdf } from "../utils/Endpoints";

// Set the workerSrc property
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function SinglePdf() {
  ///////////////////////////////////////////////////////////////////
  ///////////////////// states //////////////////////////////////////
  ///////////////////////////////////////////////////////////////////

  const [clickedIndex, setClickedIndex] = useState(null);
  const selectedPdfFileName = useSelector(
    (state) => state.data.selectedPdfFileName
  );
  const [pdfUrl, setPdfUrl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();
  const availablePdfs = useSelector((state) => state.data.pdfs);
  const availablePdfsSizes = useSelector((state) => state.data.pdfSizes);
  const chatHistory = useSelector((state) => state.data.chatHistory);
  const pdfs = useSelector((state) => state.data.pdfs);
  const accessToken = useSelector((state) => state.user.accessToken);
  const refreshToken = useSelector((state) => state.user.refreshToken);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const pdfDetails = useSelector((state) => state.data.pdfsDetails);
  const refetch = useSelector((state) => state.data.refetchGetAllPdfFiles);
  const navigate = useNavigate();
  ///////////////////////////////////////////////////////////////////
  ///////////////////// functions ///////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  const viewPdfFile = async (fileName) => {
    if (!user && !accessToken) return;

    let idToken = null;
    if (user) {
      idToken = await user.getIdToken();
    } else {
      idToken = accessToken;
    }

    try {
      let tempValue = {
        data: null,
      };

      // store it in local storage
      if (pdfDetails[fileName] === undefined || pdfDetails[fileName] === null) {
        const response = await axios.get(
          `${REACT_APP_BACKEND_URL}api/pdf/get-single-pdf-file/${fileName}/`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
            responseType: "blob", // Important for downloading files
          }
        );

        const reader = new FileReader();
        reader.readAsDataURL(response.data);

        reader.onloadend = () => {
          const base64data = reader.result;

          tempValue = {
            data: base64data,
          };

          dispatch(
            dataSlice.actions.setPdfsDetails({
              ...pdfDetails,
              [fileName]: {
                data: tempValue["data"],
              },
            })
          );

          setPdfUrl(base64data);
          setModalOpen(true); // Open the modal
        };
      } else {
        if (pdfDetails[fileName]["url"] === null) {
          const response = await axios.get(
            `${REACT_APP_BACKEND_URL}api/pdf/get-single-pdf-file/${fileName}/`,
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
              responseType: "blob", // Important for downloading files
            }
          );

          const reader = new FileReader();
          reader.readAsDataURL(response.data);

          reader.onloadend = () => {
            const base64data = reader.result;
            tempValue["data"] = base64data;

            dispatch(
              dataSlice.actions.setPdfsDetails({
                ...pdfDetails,
                [fileName]: {
                  data: tempValue["data"],
                },
              })
            );

            setPdfUrl(base64data);
            setModalOpen(true); // Open the modal
          };
        } else {
          setPdfUrl(pdfDetails[fileName]["data"]);
          setModalOpen(true); // Open the modal
        }
      }
    } catch (error) {
      console.error("Error viewing PDF file:", error);
    }
  };

  const handleItemClick = async (index, item) => {
    setClickedIndex(index);
    dispatch(dataSlice.actions.setChatType("single-pdf"));
    dispatch(dataSlice.actions.setChatHistory([]));
    const finalPdfName = `single-pdf-${item}`;

    dispatch(dataSlice.actions.setSelectedPdfFileName(item));

    if (localStorage.getItem(finalPdfName)) {
      localStorage.removeItem(finalPdfName);
    }

    let idToken = null;
    if (user) {
      idToken = await user.getIdToken();
    } else {
      idToken = accessToken;
    }

    await axios
      .get(
        REACT_APP_BACKEND_URL + "api/pdf/chat-history/",

        {
          params: {
            file_names: finalPdfName,
          },
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        const chatHistory = response.data.history;

        let finalChatHistoryLocalStorage = [];
        let finalChatHistoryDispatch = [];

        chatHistory.forEach((message) => {
          finalChatHistoryLocalStorage.push({
            user: "human",
            message: message.human,
            date: message.created_at,
          });
          finalChatHistoryLocalStorage.push({
            user: "ai",
            message: message.ai,
            date: message.created_at,
          });
          finalChatHistoryDispatch.push({
            avatar: "images/human1.png",
            position: "right",
            type: "text",
            text: message.human,
            date: message.created_at,
          });
          finalChatHistoryDispatch.push({
            avatar: "images/robot.png",
            position: "left",
            type: "text",
            text: message.ai,
            date: message.created_at,
          });
        });
        localStorage.setItem(
          finalPdfName,
          JSON.stringify(finalChatHistoryLocalStorage)
        );

        dispatch(dataSlice.actions.setChatHistory(finalChatHistoryDispatch));
      })
      .catch((error) => {
        console.error("Error getting chat history:", error);
      });
  };

  const handleExpandClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  ///////////////////////////////////////////////////////////////////
  ///////////////////// useEffect ///////////////////////////////////
  ///////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(dataSlice.actions.setChatHistory([]));
    dispatch(dataSlice.actions.setSelectedPdfFileName(null));
    dispatch(dataSlice.actions.setWhichPage("single-pdf"));
    return () => {
      dispatch(dataSlice.actions.setWhichPage(null));
    };
  }, []);

  useEffect(() => {
    if (user || accessToken) {
      // getAllPdfFiles();
      dispatch(
        dataSlice.actions.setChatHistory([
          {
            avatar: "images/robot.png",
            position: "left",
            type: "text",
            text: "Please select a PDF file to get started. 📚",
          },
        ])
      );
    } else {
      dispatch(
        dataSlice.actions.setChatHistory([
          {
            avatar: "images/robot.png",
            position: "left",
            type: "text",
            text: "Please sign in to get started. 📚",
          },
        ])
      );
    }
  }, [user, accessToken]);

  return (
    <Grid container spacing={0} sx={{ width: "100%" }}>
      {
        <Grid item xs={3}>
          <Box
            sx={{
              maxHeight: "85.5vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 2,
              borderRadius: 1,
              marginTop: 1,
              marginLeft: 1,
              border: "1px solid #ccc",
              boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
              position: "relative",
            }}
          >
            <PdfUpload />

            <Box
              sx={{
                width: "100%",
                overflowY: "auto",
                marginTop: 1,
                overflowX: "hidden", // Prevent horizontal overflow
                padding: 1,
                marginBottom: 2,
              }}
              className="custom-scrollbar"
            >
              {availablePdfs.map((item, index) => (
                <Card
                  key={index}
                  sx={{
                    bgcolor: clickedIndex === index ? "#f0f0f0" : "default",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    "&:hover": {
                      transform: "scale(1.02)",
                      boxShadow: "0 4px 2px rgba(0,0,0,0.1)",
                    },
                    marginBottom: 1,
                  }}
                  onClick={() => handleItemClick(index, item)}
                >
                  <CardActionArea disableRipple disableTouchRipple>
                    <CardContent
                      sx={{
                        paddingTop: 1,
                        paddingBottom: 1,
                        "&:last-child": {
                          paddingBottom: 1,
                        },
                      }}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={2} container justifyContent="center">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleExpandClick(index);
                            }}
                            sx={{
                              color: "#4285F4",
                              backgroundColor: "#e8eaf6",
                              "&:hover": {
                                backgroundColor: "#c5cae9",
                              },
                            }}
                          >
                            {expandedIndex === index ? (
                              <ExpandLessOutlined />
                            ) : (
                              <ExpandMoreOutlined />
                            )}
                          </IconButton>
                        </Grid>
                        <Grid item xs={2} container justifyContent="center">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              viewPdfFile(item); // Open the modal with PDF content
                            }}
                            sx={{
                              color: "#4285F4",
                              backgroundColor: "#e8eaf6",
                              "&:hover": {
                                backgroundColor: "#c5cae9",
                              },
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "14px" }}
                            noWrap
                          >
                            {item}
                          </Typography>
                        </Grid>

                        <Grid item xs={2}>
                          <IconButton
                            onClick={() => {
                              handleDeletePdf(
                                dispatch,
                                user,
                                accessToken,
                                item,
                                refetch
                              );
                            }}
                            sx={{
                              color: "#f44336",
                              backgroundColor: "#ffebee",
                              "&:hover": {
                                backgroundColor: "#ffcdd2",
                              },
                            }}
                          >
                            <DeleteOutlined
                              sx={
                                {
                                  // paddingLeft: 0,
                                }
                              }
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Collapse
                      in={expandedIndex === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <CardContent>
                        {availablePdfsSizes && availablePdfsSizes[index] && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "12px" }}
                                  noWrap
                                >
                                  Size:{" "}
                                  {availablePdfsSizes[index] < 1
                                    ? "less than 1 MB"
                                    : `${Number(
                                        availablePdfsSizes[index].toFixed(2)
                                      )} MB`}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "12px" }}
                                  noWrap
                                >
                                  {/* Number of pages:{" "} */}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        )}
                      </CardContent>
                    </Collapse>
                  </CardActionArea>
                </Card>
              ))}
            </Box>
          </Box>
        </Grid>
      }

      <Grid item xs={5}>
        <ChatBox chatEnabled={true} />
      </Grid>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            padding: 2,
            borderRadius: 1,
            boxShadow: 24,
            maxWidth: "80%",
            maxHeight: "80%",
            overflow: "auto",
          }}
        >
          {pdfUrl && (
            <Document
              file={pdfUrl}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  className="pdf-page"
                />
              ))}
            </Document>
          )}
        </Box>
      </Modal>
    </Grid>
  );
}

export default SinglePdf;
