import React, { useEffect, useState, useRef } from "react";
import { FormControl, FormHelperText, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  AppBar,
  Toolbar,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip } from "@mui/material";
import { DeleteOutline, Refresh, SaveAlt } from "@mui/icons-material";
import NotStartedIcon from "@mui/icons-material/NotStarted";

import { useDispatch, useSelector } from "react-redux";

import "react-chat-elements/dist/main.css";
import { dataSlice } from "../store/dataSlice";
import { articleSlice } from "../store/articleSlice";
import { userSlice } from "../store/userSlice";

import ShowToast from "./toast";

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ChatBoxWriteArticle = ({ chatEnabled }) => {
  ///////////////////////////////////////////////////////////////////
  ///////////////////// states //////////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  const dispatch = useDispatch();
  const chatHistory = useSelector((state) => state.data.chatHistory);
  const selectedPdfFileNames = useSelector(
    (state) => state.data.selectedPdfFileNames
  );
  const llmResponseLoading = useSelector(
    (state) => state.data.llmResponseLoading
  );

  const messageListRef = useRef(null);
  const user = useSelector((state) => state.user.user);
  const researchGap = useSelector((state) => state.article.researchGap);
  const methodology = useSelector((state) => state.article.methodology);
  const result_text = useSelector((state) => state.article.result);
  const accessToken = useSelector((state) => state.user.accessToken);

  const litRevOneByOne = useSelector((state) => state.article.litRevOneByOne);
  const litRevPage = useSelector((state) => state.article.litRevPage);
  const firstParagraph = useSelector((state) => state.article.firstParagraph);
  const secondParagraph = useSelector((state) => state.article.secondParagraph);
  const thirdParagraph = useSelector((state) => state.article.thirdParagraph);
  const fourthParagraph = useSelector((state) => state.article.fourthParagraph);
  const fifthParagraph = useSelector((state) => state.article.fifthParagraph);
  const introductionPage = useSelector(
    (state) => state.article.introductionPage
  );
  const conclusionPage = useSelector((state) => state.article.conclusionPage);
  const abstractPage = useSelector((state) => state.article.abstractPage);
  const highlights = useSelector((state) => state.article.highlights);
  const finalPaper = useSelector((state) => state.article.finalPaper);

  const [litRevOneByOneDone, setLitRevOneByOneDone] = useState(false);
  const [litRevPageDone, setLitRevPageDone] = useState(false);
  const [firstParagraphDone, setFirstParagraphDone] = useState(false);
  const [secondParagraphDone, setSecondParagraphDone] = useState(false);
  const [thirdParagraphDone, setThirdParagraphDone] = useState(false);
  const [fourthParagraphDone, setFourthParagraphDone] = useState(false);
  const [fifthParagraphDone, setFifthParagraphDone] = useState(false);
  const [introductionPageDone, setIntroductionPageDone] = useState(false);
  const [conclusionPageDone, setConclusionPageDone] = useState(false);
  const [abstractPageDone, setAbstractPageDone] = useState(false);
  const [highlightsDone, setHighlightsDone] = useState(false);
  const [finalPaperDone, setFinalPaperDone] = useState(false);
  const [chatEnabledChange, setChatEnabledChange] = useState(true);
  const jobInProgress = useSelector((state) => state.user.jobInProgress);
  const selectedProjectName = useSelector(
    (state) => state.user.selectedProjectName
  );

  const abortControllerRef = useRef(null);

  ///////////////////////////////////////////////////////////////////
  ///////////////////// functions ///////////////////////////////////
  ///////////////////////////////////////////////////////////////////

  async function writeArticle(
    request,
    setTextAction,
    setState,
    setStatusAction
  ) {

    if (
      (user || accessToken) &&
      selectedPdfFileNames.length !== 0 &&
      researchGap &&
      methodology &&
      result_text &&
      !llmResponseLoading
    ) {
      let ai_text = "";
      let response;
      let reader;
      let decoder;

      let idToken = null;
      if (user) {
        idToken = await user.getIdToken();
      } else {
        idToken = accessToken;
      }

      try {
        abortControllerRef.current = new AbortController();

        response = await fetch(
          REACT_APP_BACKEND_URL + "api/pdf/write-article/",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
            signal: abortControllerRef.current.signal,
          }
        );

        dispatch(setStatusAction("loading"));
        const text = await response.text();

        dispatch(setTextAction(text));
        setState(true);
        dispatch(setStatusAction("done"));


      } catch (error) {
        if (error.name === "AbortError") {
          ShowToast("Writing article stopped");
          dispatch(setStatusAction("idle"));
          setState(false);
        } else {
          console.error("Error writing article: ", error);
          ShowToast("Error writing article");
          setState(false);
          dispatch(setStatusAction("error"));
        }
      }
    } else {
      setState(false);
    }
  }

  

  const handleSendMessage = async () => {
    if (!user && !accessToken) {
      ShowToast("Please login first");
      return;
    }

    if (!researchGap || !methodology || !result_text) {
      ShowToast("Please set the inputs");
      return;
    }
    if (llmResponseLoading) {
      ShowToast("One task is being processed");
      return;
    }

    dispatch(articleSlice.actions.setLitRevOneByOne(""));
    dispatch(articleSlice.actions.setLitRevPage(""));
    dispatch(articleSlice.actions.setFirstParagraph(""));
    dispatch(articleSlice.actions.setSecondParagraph(""));
    dispatch(articleSlice.actions.setThirdParagraph(""));
    dispatch(articleSlice.actions.setFourthParagraph(""));
    dispatch(articleSlice.actions.setFifthParagraph(""));
    dispatch(articleSlice.actions.setIntroductionPage(""));
    dispatch(articleSlice.actions.setConclusionPage(""));
    dispatch(articleSlice.actions.setAbstractPage(""));
    dispatch(articleSlice.actions.setHighlights(""));
    dispatch(articleSlice.actions.setFinalPaper(""));
    setLitRevOneByOneDone(false);
    setLitRevPageDone(false);
    setFirstParagraphDone(false);
    setSecondParagraphDone(false);
    setThirdParagraphDone(false);
    setFourthParagraphDone(false);
    setFifthParagraphDone(false);
    setIntroductionPageDone(false);
    setConclusionPageDone(false);
    setAbstractPageDone(false);
    setHighlightsDone(false);
    setFinalPaperDone(false);

    dispatch(articleSlice.actions.setLitRevOneByOneGeneratingStatus("idle"));
    dispatch(articleSlice.actions.setLitRevPageGeneratingStatus("idle"));
    dispatch(articleSlice.actions.setFirstParagraphGeneratingStatus("idle"));
    dispatch(articleSlice.actions.setSecondParagraphGeneratingStatus("idle"));
    dispatch(articleSlice.actions.setThirdParagraphGeneratingStatus("idle"));
    dispatch(articleSlice.actions.setFourthParagraphGeneratingStatus("idle"));
    dispatch(articleSlice.actions.setFifthParagraphGeneratingStatus("idle"));
    dispatch(articleSlice.actions.setIntroductionPageGeneratingStatus("idle"));
    dispatch(articleSlice.actions.setConclusionPageGeneratingStatus("idle"));
    dispatch(articleSlice.actions.setAbstractPageGeneratingStatus("idle"));
    dispatch(articleSlice.actions.setHighlightsGeneratingStatus("idle"));

    const request = {
      file_names: selectedPdfFileNames,
      methodology: methodology,
      research_gap: researchGap,
      results: result_text,
      article_part: "lit_rev_one_by_one",
      project_name: selectedProjectName,
    };
    dispatch(userSlice.actions.setJobInProgress(true));

    writeArticle(
      request,
      articleSlice.actions.setLitRevOneByOne,
      setLitRevOneByOneDone,
      articleSlice.actions.setLitRevOneByOneGeneratingStatus
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const scrollToBottom = () => {
    if (messageListRef && messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// useEffects ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  const results = useSelector((state) => state.article.result);

  const litRevOneByOneGeneratingStatus = useSelector(
    (state) => state.article.litRevOneByOneGeneratingStatus
  );
  const litRevPageGeneratingStatus = useSelector(
    (state) => state.article.litRevPageGeneratingStatus
  );
  const firstParagraphGeneratingStatus = useSelector(
    (state) => state.article.firstParagraphGeneratingStatus
  );
  const secondParagraphGeneratingStatus = useSelector(
    (state) => state.article.secondParagraphGeneratingStatus
  );
  const thirdParagraphGeneratingStatus = useSelector(
    (state) => state.article.thirdParagraphGeneratingStatus
  );
  const fourthParagraphGeneratingStatus = useSelector(
    (state) => state.article.fourthParagraphGeneratingStatus
  );
  const fifthParagraphGeneratingStatus = useSelector(
    (state) => state.article.fifthParagraphGeneratingStatus
  );

  const introductionPageGeneratingStatus = useSelector(
    (state) => state.article.introductionPageGeneratingStatus
  );

  const conclusionPageGeneratingStatus = useSelector(
    (state) => state.article.conclusionPageGeneratingStatus
  );
  const abstractPageGeneratingStatus = useSelector(
    (state) => state.article.abstractPageGeneratingStatus
  );

  const highlightsGeneratingStatus = useSelector(
    (state) => state.article.highlightsGeneratingStatus
  );

  const [finalArticle, setFinalArticle] = useState(false);

  useEffect(() => {
    if (
      litRevOneByOneGeneratingStatus === "done" &&
      litRevPageGeneratingStatus === "done" &&
      abstractPageGeneratingStatus === "done" &&
      highlightsGeneratingStatus === "done" &&
      introductionPageGeneratingStatus === "done" &&
      conclusionPageGeneratingStatus === "done"
    ) {
      setFinalArticle(true);
    }
  }, [
    litRevOneByOneGeneratingStatus,
    litRevPageGeneratingStatus,
    abstractPageGeneratingStatus,
    highlightsGeneratingStatus,
    introductionPageGeneratingStatus,
    conclusionPageGeneratingStatus,
    abstractPage,
    introductionPage,
    methodology,
    results,
    conclusionPage,
  ]);

  useEffect(() => {
    if (selectedProjectName) {
      dispatch(articleSlice.actions.setLitRevOneByOne(null));
      dispatch(articleSlice.actions.setLitRevPage(null));
      dispatch(articleSlice.actions.setFirstParagraph(null));
      dispatch(articleSlice.actions.setSecondParagraph(null));
      dispatch(articleSlice.actions.setThirdParagraph(null));
      dispatch(articleSlice.actions.setFourthParagraph(null));
      dispatch(articleSlice.actions.setFifthParagraph(null));
      dispatch(articleSlice.actions.setAbstractPage(null));
      dispatch(articleSlice.actions.setConclusionPage(null));
      dispatch(articleSlice.actions.setHighlights(null));
      dispatch(articleSlice.actions.setIntroductionPage(null));

      dispatch(articleSlice.actions.setLitRevOneByOneGeneratingStatus("idle"));
    }
  }, [selectedProjectName]);


  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  useEffect(() => {
    //// litRevPage //////
    if (litRevOneByOneDone) {
      const request = {
        file_names: selectedPdfFileNames,
        methodology: methodology,
        research_gap: researchGap,
        results: result_text,
        article_part: "lit_rev_page",
        lit_rev_one_by_one: litRevOneByOne,
        project_name: selectedProjectName,
      };
      writeArticle(
        request,
        articleSlice.actions.setLitRevPage,
        setLitRevPageDone,
        articleSlice.actions.setLitRevPageGeneratingStatus
      );
    }
  }, [litRevOneByOneDone]);

  useEffect(() => {
    //// 1st paragraph ////
    if (litRevPageDone) {
      const request = {
        file_names: selectedPdfFileNames,
        methodology: methodology,
        research_gap: researchGap,
        results: result_text,
        article_part: "first_paragraph",
        lit_rev_page: litRevPage,
        project_name: selectedProjectName,
      };
      writeArticle(
        request,
        articleSlice.actions.setFirstParagraph,
        setFirstParagraphDone,
        articleSlice.actions.setFirstParagraphGeneratingStatus
      );
    }
  }, [litRevPageDone]);

  useEffect(() => {
    //// 2nd paragraph ////
    if (firstParagraphDone) {
      const request = {
        file_names: selectedPdfFileNames,
        methodology: methodology,
        research_gap: researchGap,
        results: result_text,
        article_part: "second_paragraph",
        lit_rev_page: litRevPage,
        project_name: selectedProjectName,
      };

      writeArticle(
        request,
        articleSlice.actions.setSecondParagraph,
        setSecondParagraphDone,
        articleSlice.actions.setSecondParagraphGeneratingStatus
      );
    }
  }, [firstParagraphDone]);

  useEffect(() => {
    //// 3rd paragraph ////
    if (secondParagraphDone) {
      const request = {
        file_names: selectedPdfFileNames,
        methodology: methodology,
        research_gap: researchGap,
        results: result_text,
        article_part: "third_paragraph",
        lit_rev_page: litRevPage,
        project_name: selectedProjectName,
      };

      writeArticle(
        request,
        articleSlice.actions.setThirdParagraph,
        setThirdParagraphDone,
        articleSlice.actions.setThirdParagraphGeneratingStatus
      );
    }
  }, [secondParagraphDone]);

  useEffect(() => {
    //// 4th paragraph ////
    if (thirdParagraphDone) {
      const request = {
        file_names: selectedPdfFileNames,
        methodology: methodology,
        research_gap: researchGap,
        results: result_text,
        article_part: "fourth_paragraph",
        first_paragraph: firstParagraph,
        second_paragraph: secondParagraph,
        third_paragraph: thirdParagraph,
        research_gap: researchGap,
        project_name: selectedProjectName,
      };

      writeArticle(
        request,
        articleSlice.actions.setFourthParagraph,
        setFourthParagraphDone,
        articleSlice.actions.setFourthParagraphGeneratingStatus
      );
    }
  }, [thirdParagraphDone]);

  useEffect(() => {
    //// 5th paragraph ////
    if (fourthParagraphDone) {
      const request = {
        file_names: selectedPdfFileNames,
        methodology: methodology,
        research_gap: researchGap,
        results: result_text,
        article_part: "fifth_paragraph",
        first_paragraph: firstParagraph,
        second_paragraph: secondParagraph,
        third_paragraph: thirdParagraph,
        fourth_paragraph: fourthParagraph,
        research_gap: researchGap,
        project_name: selectedProjectName,
      };

      writeArticle(
        request,
        articleSlice.actions.setFifthParagraph,
        setFifthParagraphDone,
        articleSlice.actions.setFifthParagraphGeneratingStatus
      );
    }
  }, [fourthParagraphDone]);

  useEffect(() => {
    //// Introduction Page ////
    if (fifthParagraphDone) {
      const request = {
        file_names: selectedPdfFileNames,
        methodology: methodology,
        research_gap: researchGap,
        results: result_text,
        first_paragraph: firstParagraph,
        second_paragraph: secondParagraph,
        third_paragraph: thirdParagraph,
        fourth_paragraph: fourthParagraph,
        fifth_paragraph: fifthParagraph,
        article_part: "introduction_page",
        research_gap: researchGap,
        project_name: selectedProjectName,
      };

      writeArticle(
        request,
        articleSlice.actions.setIntroductionPage,
        setIntroductionPageDone,
        articleSlice.actions.setIntroductionPageGeneratingStatus
      );
    }
  }, [fifthParagraphDone]);

  useEffect(() => {
    //// conclusion Page ////
    if (introductionPageDone) {
      const request = {
        file_names: selectedPdfFileNames,
        methodology: methodology,
        research_gap: researchGap,
        results: result_text,
        introduction_page: introductionPage,
        lit_rev_page: litRevPage,
        article_part: "conclusion",
        research_gap: researchGap,
        project_name: selectedProjectName,
      };

      writeArticle(
        request,
        articleSlice.actions.setConclusionPage,
        setConclusionPageDone,
        articleSlice.actions.setConclusionPageGeneratingStatus
      );
    }
  }, [introductionPageDone]);

  useEffect(() => {
    //// abstract Page ////
    if (conclusionPageDone) {
      const request = {
        file_names: selectedPdfFileNames,
        methodology: methodology,
        research_gap: researchGap,
        results: result_text,
        introduction_page: introductionPage,
        lit_rev_page: litRevPage,
        conclusion: conclusionPage,
        article_part: "abstract",
        research_gap: researchGap,
        project_name: selectedProjectName,
      };

      writeArticle(
        request,
        articleSlice.actions.setAbstractPage,
        setAbstractPageDone,
        articleSlice.actions.setAbstractPageGeneratingStatus
      );
    }
  }, [conclusionPageDone]);

  useEffect(() => {
    //// highlight Page ////
    if (abstractPageDone) {
      const request = {
        file_names: selectedPdfFileNames,
        methodology: methodology,
        research_gap: researchGap,
        results: result_text,

        introduction_page: introductionPage,
        lit_rev_page: litRevPage,
        conclusion: conclusionPage,
        article_part: "highlights",
        research_gap: researchGap,
        project_name: selectedProjectName,
      };

      writeArticle(
        request,
        articleSlice.actions.setHighlights,
        setHighlightsDone,
        articleSlice.actions.setHighlightsGeneratingStatus
      );

      localStorage.setItem("litRevOneByOne", litRevOneByOne);
      localStorage.setItem("litRevPage", litRevPage);
      localStorage.setItem("firstParagraph", firstParagraph);
      localStorage.setItem("secondParagraph", secondParagraph);
      localStorage.setItem("thirdParagraph", thirdParagraph);
      localStorage.setItem("fourthParagraph", fourthParagraph);
      localStorage.setItem("fifthParagraph", fifthParagraph);
      localStorage.setItem("introductionPage", introductionPage);
      localStorage.setItem("conclusionPage", conclusionPage);
      localStorage.setItem("abstractPage", abstractPage);
      localStorage.setItem("highlights", highlights);
      dispatch(dataSlice.actions.setLlmResponseLoading(false));
      dispatch(userSlice.actions.setJobInProgress(false));
    }
  }, [abstractPageDone]);

  useEffect(() => {
    if (abortControllerRef.current) {
      handleStopWritingArticle(dispatch, abortControllerRef);
    }
  }, [selectedProjectName]);



  return (
    <Box
      sx={{
        border: "1px solid #ccc",
        marginLeft: 1,
        height: "90vh",
        marginTop: 1,
        borderRadius: 1,
        backgroundColor: "#f9f9f9",
        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        overflowY: "auto", // Add this line to make the box scrollable
        overflowX: "hidden",
      }}
    >
      {/* Write Article Button */}
      <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center", // Added this line to center the button
            padding: 1,
            borderBottomLeftRadius: 1,
            borderBottomRightRadius: 1,
            alignContent: "center",
          }}
        >
          {!jobInProgress && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendMessage}
              sx={{ width: "50%", height: "100%", marginTop: 0 }}
            >
              {llmResponseLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Write Article"
              )}
            </Button>
          )}

          {jobInProgress && (
            <Button
              variant="contained"
              color="error"
              onClick={() =>
                handleStopWritingArticle(dispatch, abortControllerRef)
              }
              sx={{ width: "100%", height: "100%", marginTop: 5 }}
            >
              {llmResponseLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Stop Writing Article"
              )}
            </Button>
          )}
        </Box>

      {/* <AppBar
        position="static"
        color="transparent"
        elevation={0}
        sx={{ backgroundColor: "#f0f0f0" }}
      >
        <Toolbar
          variant="dense"
          sx={{
            minHeight: "auto",
            padding: 0,
            justifyContent: "space-between", // Adjust to space-between
            border: "0px",
          }}
        >
          <Typography
            sx={{
              padding: "4px 8px",
              fontWeight: "bold",
            }}
          >
            {selectedPdfFileNames.length} PDF file(s) selected.
          </Typography>
        </Toolbar>
      </AppBar> */}

      <Box mt={4} width="100%">
        
        {/* Research Gap */}
        <PaperSectionsInputByUser
          chatEnabled={chatEnabled}
          handleKeyDown={handleKeyDown}
          placeHolder="Start here: Please enter the research gap here."
          sectionName="researchGap"
        />

        {/* Informing the lit rev is being conducted */}
        <SectionStatus
          sectionName="Processing input pdf files"
          content={litRevOneByOne}
          isGenerating={litRevOneByOneGeneratingStatus === "loading"}
          isError={false}
          isDone={litRevOneByOneGeneratingStatus === "done"}
        />

        {/* Highlights */}
        <Typography textAlign="left" width="100%" paddingLeft={2}>
          <h2>Highlights</h2>
          <ReactMarkdown>{highlights}</ReactMarkdown>
        </Typography>
        <SectionStatus
          sectionName="Generating highlights"
          content={highlights}
          isGenerating={highlightsGeneratingStatus === "loading"}
          isError={false}
          isDone={highlightsGeneratingStatus === "done"}
        />


        {/* Abstract */}
        <Typography textAlign="left" width="100%" paddingLeft={2}>
          <h2>Abstract</h2>
          <ReactMarkdown>{abstractPage}</ReactMarkdown>
        </Typography>
        <SectionStatus
          sectionName="Generating abstract"
          content={abstractPage}
          isGenerating={abstractPageGeneratingStatus === "loading"}
          isError={false}
          isDone={abstractPageGeneratingStatus === "done"}
        />

        {/* Introduction */}
        <Typography textAlign="left" width="100%" paddingLeft={2}>
          <h2>Introduction</h2>
          <ReactMarkdown>{introductionPage}</ReactMarkdown>
        </Typography>
        <SectionStatus
          sectionName="Working on 1st introduction paragraph"
          content={firstParagraph}
          isGenerating={firstParagraphGeneratingStatus === "loading"}
          isError={false}
          isDone={firstParagraphGeneratingStatus === "done"}
        />
        <SectionStatus
          sectionName="Working on 2nd introduction paragraph"
          content={secondParagraph}
          isGenerating={secondParagraphGeneratingStatus === "loading"}
          isError={false}
          isDone={secondParagraphGeneratingStatus === "done"}
        />
        <SectionStatus
          sectionName="Working on 3rd introduction paragraph"
          content={thirdParagraph}
          isGenerating={thirdParagraphGeneratingStatus === "loading"}
          isError={false}
          isDone={thirdParagraphGeneratingStatus === "done"}
        />
        <SectionStatus
          sectionName="Working on 4th introduction paragraph"
          content={fourthParagraph}
          isGenerating={fourthParagraphGeneratingStatus === "loading"}
          isError={false}
          isDone={fourthParagraphGeneratingStatus === "done"}
        />
        <SectionStatus
          sectionName="Working on 5th introduction paragraph"
          content={fifthParagraph}
          isGenerating={fifthParagraphGeneratingStatus === "loading"}
          isError={false}
          isDone={fifthParagraphGeneratingStatus === "done"}
        />
        <SectionStatus
          sectionName="Finalizing introduction"
          content={introductionPage}
          isGenerating={introductionPageGeneratingStatus === "loading"}
          isError={false}
          isDone={introductionPageGeneratingStatus === "done"}
        />
        
        {/* Literature Review */}
        <Typography textAlign="left" width="100%" paddingLeft={2}>
          <h2>Literature Review</h2>
          <ReactMarkdown>{litRevPage}</ReactMarkdown>
        </Typography>
        <SectionStatus
          sectionName="Generating literature review"
          content={litRevPage}
          isGenerating={litRevPageGeneratingStatus === "loading"}
          isError={false}
          isDone={litRevPageGeneratingStatus === "done"}
        />

        {/* Methodology */}
        <Typography textAlign="left" width="100%" paddingLeft={2}>
          <h2>Materials and Methods</h2>
          {/* <ReactMarkdown>{methodology}</ReactMarkdown> */}
        </Typography>
        <PaperSectionsInputByUser
          chatEnabled={chatEnabled}
          handleKeyDown={handleKeyDown}
          placeHolder="Please enter the methodology here."
          sectionName="methodology"
        />


        {/* Results */}
        <Typography textAlign="left" width="100%" paddingLeft={2}>
          <h2>Results</h2>
          {/* <ReactMarkdown>{results}</ReactMarkdown> */}
        </Typography>
        <PaperSectionsInputByUser 
          chatEnabled={chatEnabled}
          handleKeyDown={handleKeyDown}
          placeHolder="Please enter the results here."
          sectionName="result"
        />

        {/* Conclusion */}
        <Typography textAlign="left" width="100%" paddingLeft={2}>
          <h2>Conclusion</h2>
          <ReactMarkdown>{conclusionPage}</ReactMarkdown>
        </Typography>
        <SectionStatus
          sectionName="Generating conclusion page"
          content={conclusionPage}
          isGenerating={conclusionPageGeneratingStatus === "loading"}
          isError={false}
          isDone={conclusionPageGeneratingStatus === "done"}
        />

      </Box>

    </Box>
  );
};

export default ChatBoxWriteArticle;



// Some useful snippets for the above code:
const SectionStatus = ({
  sectionName,
  content,
  isGenerating,
  isError,
  isDone,
}) => {
  return isGenerating || isDone ? (
    <Card variant="outlined" sx={{ width: "100%" }}>
      <CardContent
        sx={{
          paddingTop: 1,
          paddingBottom: 1,
          "&:last-child": {
            paddingBottom: 1,
          },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          {isGenerating && (
            <Box display="flex" alignItems="center" ml={1} mr={1}>
              <CircularProgress size={20} />
            </Box>
          )}
          {!isGenerating && !isError && !isDone && (
            <Box display="flex" alignItems="center" ml={1} mr={1}>
              <NotStartedIcon color="primary" />
            </Box>
          )}
          {isError && (
            <Box display="flex" alignItems="center" ml={1} mr={1}>
              <ErrorIcon color="error" />
              <Typography ml={1}>Error</Typography>
            </Box>
          )}
          {isDone && (
            <Box display="flex" alignItems="center" ml={1} mr={1}>
              <CheckCircleIcon color="success" />
            </Box>
          )}
          <Typography fontWeight="bold" textAlign="center" width="100%">
            {sectionName}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  ) : null;
};


function PaperSectionsInputByUser({
  chatEnabled,
  handleKeyDown,
  placeHolder,
  sectionName
}) {

  const dispatch = useDispatch();

  const handleInputChange = (which) => (event) => {
    if (which === "researchGap") {
      dispatch(articleSlice.actions.setResearchGap(event.target.value));
    } else if (which === "methodology") {
      dispatch(articleSlice.actions.setMethodology(event.target.value));
    } else if (which === "result") {
      dispatch(articleSlice.actions.setResult(event.target.value));
    }
  };

  const value = useSelector((state) => state.article[sectionName]);


  const title = "Describe the " + sectionName;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 1,
        backgroundColor: "#f9f9f9",
        borderBottomLeftRadius: 1,
        borderBottomRightRadius: 1,
      }}
    >
      <FormControl fullWidth sx={{ marginBottom: 0 }}>
        <Tooltip title= {title}  arrow>
          <TextField
            disabled={!chatEnabled}
            placeholder={placeHolder}
            multiline
            fullWidth
            rows={5}
            value={value}
            onChange={handleInputChange(sectionName)}
            onKeyDown={handleKeyDown}
            variant="outlined"
            sx={{
              marginRight: 2,
              backgroundColor: "#fff",
            }}
          />
        </Tooltip>
      </FormControl>
    </Box>
  )
}



function handleStopWritingArticle(dispatch, abortControllerRef) {
  
  dispatch(userSlice.actions.setJobInProgress(false));
  abortControllerRef.current.abort();
}
