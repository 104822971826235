import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Grid,
  Paper,
  Alert,
  Collapse,
  CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import GoogleSignInButton from "../components/GoogleSignInButton";
import axios from "axios";
import { handleGoogleSignIn, handleSignOut } from "../utils/Auth";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { dataSlice } from "../store/dataSlice";

import ResendConfirmationEmail from "../ResendConfirmationEmail"; // Import the component
import CheckYourEmail from "../components/CheckYourEmail";
import { userSlice } from "../store/userSlice";

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const BackgroundContainer = styled(Box)({
  height: "90vh",
  marginTop: "2px",
  alignItems: "center",
  justifyContent: "center",
  background: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
  padding: "0 16px",
  overflow: "auto",
});

const Logo = styled(Typography)({
  fontSize: "2rem",
  fontWeight: "bold",
  color: "#3f51b5",
  textAlign: "center",
  marginBottom: "10px",
});

const AuthPage = () => {
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [cooldown, setCooldown] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const recaptchaRef = useRef(null);
  const [captchaError, setCaptchaError] = useState(false);
  const [showResendEmail, setShowResendEmail] = useState(false); // State to toggle ResendConfirmationEmail component
  const [message, setMessage] = useState(null);
  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.accessToken);
  const navigateToHomeAndSignIn = async () => {
    await handleGoogleSignIn();
    navigate("/");
  };
  const dispatch = useDispatch();

  const signupValidationSchema = yup.object({
    name: yup
      .string("Enter your name")
      .min(3, "Name should be of minimum 3 characters length")
      .required("Name is required"),
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Enter a valid email"
      )
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(6, "Password should be of minimum 6 characters length")
      .required("Password is required"),
  });

  const loginValidationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Enter a valid email"
      )
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(6, "Password should be of minimum 6 characters length")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      phone: "11111111111",
    },
    validationSchema: isRegistering
      ? signupValidationSchema
      : loginValidationSchema,
    onSubmit: async (values) => {
      if (!captchaToken) {
        setCaptchaError(true); // Set the error state if reCAPTCHA is not clicked
        return;
      }

      setError("");
      setLoading(true);
      setCooldown(true);

      if (isRegistering) {
        try {
          const response = await axios.post(
            `${REACT_APP_BACKEND_URL}api/auth/register-recaptcha/`,
            {
              ...values,
              phone: "1111111111",
              captcha_token: captchaToken,
            }
          );

          setEmail(values.email);
          setIsRegistering(false);
          dispatch(dataSlice.actions.setCheckEmail(true));
        } catch (error) {
          console.error("Error registering user: ", error);
          if (
            error.response &&
            error.response.data.error === "User already exists"
          ) {
            setError(
              "Email already exists. Please register with a different email address"
            );
          } else if (
            error.response &&
            error.response.data.error === "Please use your university email"
          ) {
            setError("Please use your university email");
          } else {
            setError("Failed to register. Please try again.");
          }
        } finally {
          setLoading(false);
          setCaptchaToken("");
          recaptchaRef.current.reset();
        }
      } else {
        try {
          const response = await axios.post(
            `${REACT_APP_BACKEND_URL}api/auth/login/`,
            {
              username: values.email,
              password: values.password,
              captcha_token: captchaToken,
            }
          );
          // iterate over all key values on the localStoarage
          for (const [key, value] of Object.entries(localStorage)) {
            if (key.startsWith("multi-pdf") || key.startsWith("single-pdf")) {
              localStorage.removeItem(key);
            }
          }

          localStorage.setItem("accessToken", response.data.access);
          dispatch(userSlice.actions.setAccessToken(response.data.access));

          localStorage.setItem("refreshToken", response.data.refresh);
          dispatch(userSlice.actions.setRefreshToken(response.data.refresh));

          navigate("/");
          window.location.reload();
        } catch (error) {
          console.error("Error logging in: ", error.response.data.error[0]);
          if (
            error.response &&
            error.response.data.error[0] === "email or password is incorrect"
          ) {
            setError("Email or password is incorrect. Please try again.");
          } else if (
            error.response &&
            error.response.data.error[0] === "user is not activated"
          ) {
            setError(
              "User is not activated. Please check your email and activate your account."
            );
          } else {
            setError("Failed to login. Please try again.");
          }
        } finally {
          setLoading(false);
          setCaptchaToken("");
          recaptchaRef.current.reset();
        }
      }
    },
  });

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  useEffect(() => {
    dispatch(dataSlice.actions.setWhichPage("auth-page"));
    return () => {
      dispatch(dataSlice.actions.setWhichPage(null));
    };
  }, []);

  useEffect(() => {
    if (captchaToken) {
      setCaptchaError(false);
    }
  }, [captchaToken]);
  const setShowResendEmailFunction = () => {
    setShowResendEmail(!showResendEmail);
  };

  useEffect(() => {
    if (cooldown) {
      const timer = setTimeout(() => {
        setCooldown(false);
      }, 2000);

      // Cleanup the timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [cooldown]);

  useEffect(() => {
    async function checkAuthenticity() {
      let idToken = null;

      if (user || accessToken) {
        if (user) {
          idToken = user.getIdToken();
        } else {
          idToken = accessToken;
        }

        await axios
          .get(REACT_APP_BACKEND_URL + "api/auth/profile-simple", {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              navigate("/");
            } else {
              // navigate("/auth");
              // handleSignOut();
            }
          })
          .catch((error) => {
            console.error("Error getting user:", error);
          });
      }
    }

    checkAuthenticity();
  }, [user, accessToken]);

  
  return (
    <BackgroundContainer>
      <Container component="main" maxWidth="xs">
       
        <Paper
          elevation={6}
          sx={{
            paddingTop: 1,
            paddingBottom: 0,
            paddingLeft: 2,
            paddingRight: 2,
            borderRadius: 2,
            marginTop: "15vh",
          }}
        >
          <Logo>CE Assistant</Logo>
          <Box sx={{ mt: 2 }}>
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <GoogleSignInButton
                onClick={navigateToHomeAndSignIn}
                disabled={true}
              />
            </Box> */}
            {/* <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              OR
            </Typography> */}

            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}

            <form onSubmit={formik.handleSubmit}>
              {isRegistering && (
                <TextField
                  disabled={loading || cooldown}
                  label="Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  fullWidth
                  margin="normal"
                  required
                  defaultValue=""
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              <TextField
                disabled={loading || cooldown}
                label="Email"
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
                margin="normal"
                required
                defaultValue=""
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                disabled={loading || cooldown}
                label="Password"
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                fullWidth
                margin="normal"
                required
                defaultValue=""
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center", // Center horizontally
                  mt: 1,
                  width: "100%",
                }}
              >
                {!loading && !cooldown && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      // width: "100%",
                      border: captchaError ? "2px solid #d32f2f" : "0px",
                      borderRadius: 1,
                    }}
                  >
                    {" "}
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={RECAPTCHA_SITE_KEY}
                      onChange={handleCaptchaChange}
                    />
                  </Box>
                )}
              </Box>{" "}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                disabled={loading || cooldown}
              >
                {loading || cooldown ? (
                  <CircularProgress size={24} color="inherit" />
                ) : isRegistering ? (
                  "Register"
                ) : (
                  "Sign In"
                )}
              </Button>
            </form>
            <Grid container justifyContent="center" sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  sx={{
                    color: "#3f51b5",
                    borderColor: "#3f51b5",
                    backgroundColor: "#e8eaf6",
                  }}
                  variant="outlined"
                  onClick={() => {
                    setIsRegistering(!isRegistering);
                    formik.resetForm();
                  }}
                >
                  {isRegistering
                    ? "Already have an account? Sign In"
                    : "Don't have an account? Register"}
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" sx={{ mt: 2 }}>
              <Grid item xs={12} mb={2}>
                <Button
                  variant="outlined"
                  sx={{
                    color: "#4caf50",
                    borderColor: "#4caf50",
                    backgroundColor: "#e8f5e9",
                  }}
                  fullWidth
                  onClick={() => {
                    setShowResendEmail(!showResendEmail);
                  }}
                >
                  Resend Activation Link
                </Button>
              </Grid>
            </Grid>
          </Box>

          <ResendConfirmationEmail
            open={showResendEmail}
            onClose={() => setShowResendEmailFunction(false)}
            message={message}
            setMessage={setMessage}
          />
        </Paper>
      </Container>
      <CheckYourEmail email={email} />
    </BackgroundContainer>
  );
};

export default AuthPage;
