import React, { useState, useCallback } from "react";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import Box from "@mui/material/Box";
import {
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  Grid,
  Collapse,
  Menu,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import axios from "axios";
import { Button } from "@mui/material";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import Modal from "@mui/material/Modal";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dataSlice, setChatHistory, setPdfs } from "../store/dataSlice";
import PdfUpload from "../components/PdfUpload";

import "../css/customScrollbar.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import ChatBoxWriteArticle, {
  ShowToast,
} from "../components/ChatBoxWriteArticle";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "../utils/Auth";
import { handleDeletePdf, handleSaveNewProject } from "../utils/Endpoints";
import { userSlice } from "../store/userSlice";
import { PdfDisplay } from "../components/PdfDisplay";

import CreateNewProject from "../components/CreateNewProject";


const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

function WriteArticlePage() {
  ///////////////////////////////////////////////////////////////////
  ///////////////////// states //////////////////////////////////////
  ///////////////////////////////////////////////////////////////////

  const llmResponseLoading = useSelector(
    (state) => state.data.llmResponseLoading
  );
  const refetchGetAllProjects = useSelector(
    (state) => state.data.refetchGetAllProjects
  );
  const user = useSelector((state) => state.user.user);
  const selectedProjectName = useSelector(
    (state) => state.user.selectedProjectName
  );
  const [newProjectDialogOpen, setNewProjectDialogOpen] = useState(true);
  const [newProjectName, setNewProjectName] = useState("");

  const [expandedIndex, setExpandedIndex] = useState(null);
  const availablePdfsSizes = useSelector((state) => state.data.pdfSizes);

  const accessToken = useSelector((state) => state.user.accessToken);
  const selectedPdfFileNames = useSelector(
    (state) => state.data.selectedPdfFileNames
  );
  const projects = useSelector((state) => state.user.projects);

  const dispatch = useDispatch();

  const [pdfUrl, setPdfUrl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFilesChanged, setSelectedFilesChanged] = useState(false);

  const [numPages, setNumPages] = useState(null);

  const jobInProgress = useSelector((state) => state.user.jobInProgress);
  const refetchGetAllPdfFiles = useSelector(
    (state) => state.data.refetchGetAllPdfFiles
  );
  ///////////////////////////////////////////////////////////////////
  ///////////////////// useEffect ///////////////////////////////////
  //////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(dataSlice.actions.setWhichPage("write-article"));
    return () => {
      dispatch(dataSlice.actions.setWhichPage(null));
    };
  }, []);
  useEffect(() => {
    if (user || accessToken) {
      dispatch(
        dataSlice.actions.setChatHistory([
          {
            avatar: "images/robot.png",
            position: "left",
            type: "text",
            text: "Please select from the PDF files to get started. 📚",
          },
        ])
      );
    } else {
      dispatch(
        dataSlice.actions.setChatHistory([
          {
            avatar: "images/robot.png",
            position: "left",
            type: "text",
            text: "Please sign in to get started. 📚",
          },
        ])
      );
    }
  }, [user, accessToken]);

  useEffect(() => {
    dispatch(dataSlice.actions.setSelectedPdfFileNames([]));
    dispatch(dataSlice.actions.setChatHistory([]));

    // setSelectedIndices([]);
  }, []);

  return (
    <Grid container spacing={0} sx={{ width: "100%" }}>
      {selectedProjectName ? (
        <>
          <Grid item xs={3}>
            <Box
              sx={{
                maxHeight: "85.5vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 2,
                borderRadius: 1,
                marginTop: 1,
                marginLeft: 1,
                border: "1px solid #ccc",
                boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                backgroundColor: jobInProgress
                  ? "rgba(0, 0, 0, 0.1)"
                  : "inherit", // Gray out the box
                pointerEvents: jobInProgress ? "none" : "auto", // Prevent interaction
              }}
            >
              <PdfUpload />
              <PdfDisplay />
            </Box>
          </Grid>
            
          {/* The middle section */}
          <Grid item xs={5}>
            <ChatBoxWriteArticle chatEnabled={true} />
          </Grid>
            
          {/* The right side paper section */}
          {/* <Grid item xs={4}>
            <Box
              sx={{
                marginTop: 1,
                height: "90vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: 1,
                border: "1px solid #ccc",
                overflowY: "auto",
                marginLeft: 1,
                paddingLeft: 2,
                marginRight: 1,
                paddingRight: 2,
                boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                backgroundColor: "#f9f9f9",
              }}
            >
              <PaperComponent />
            </Box>
          </Grid> */}
        </>
      ) : (
        projects.length === 0 && (
          <>
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              You don't have any projects yet. Let's create one!
            </Typography>
            
            <CreateNewProject
              newProjectDialogOpen = {newProjectDialogOpen}
              setNewProjectDialogOpen = {setNewProjectDialogOpen}
              newProjectName = {newProjectName}
              setNewProjectName = {setNewProjectName}
              dispatch = {dispatch}
              dataSlice = {dataSlice}
              accessToken = {accessToken}
              refetchGetAllProjects = {refetchGetAllProjects}
              userSlice = {userSlice}
              refetchGetAllPdfFiles = {refetchGetAllPdfFiles} 
            />
          </>
        )
      )}
    </Grid>
  );
}

export default WriteArticlePage;
