import axios from "axios";
import ShowToast from "../components/toast";
import { dataSlice } from "../store/dataSlice";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "react-toastify/dist/ReactToastify.css";
const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const handleDeletePdf = async (
  dispatch,
  user,
  accessToken,
  fileName,
  refetchGetAllPdfFiles,
  currentSelectedPdfFileNames,
  actionFunction,
  projectName
) => {
  if (!user && !accessToken) return;
  let idToken = null;
  if (user) {
    idToken = await user.getIdToken();
  } else {
    idToken = accessToken;
  }

  await axios
    .delete(REACT_APP_BACKEND_URL + `api/pdf/delete-pdf-file/${fileName}/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        project_name: projectName,
      },
    })
    .then((response) => {
      if (response.status === 200) {

        dispatch(
          dataSlice.actions.setRefetchGetAllPdfFiles(!refetchGetAllPdfFiles)
        );

        if (currentSelectedPdfFileNames.includes(fileName)) {
          const newSelectedPdfFileNames = currentSelectedPdfFileNames.filter(
            (name) => name !== fileName
          );
          dispatch(actionFunction(newSelectedPdfFileNames));
        }
      }
    })
    .catch((error) => {
      console.error("Error deleting PDF file:", error);
    });
};

export const handleSaveNewProject = async (
  dispatch,
  dataSlice,
  newProjectName,
  accessToken,
  refetchGetAllProjects,
  setNewProjectName,
  setNewProjectDialogOpen,
  userSlice,
  refetchGetAllPdfFiles
) => {
  if (newProjectName === "") {
    ShowToast("Please enter a project name.");
    return;
  }

  await axios
    .post(
      REACT_APP_BACKEND_URL + "api/pdf/project/",
      {
        name: newProjectName,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      dispatch(
        dataSlice.actions.setRefetchGetAllProjects(!refetchGetAllProjects)
      );

      dispatch(userSlice.actions.setSelectedProjectName(newProjectName));
      dispatch(
        dataSlice.actions.setRefetchGetAllPdfFiles(!refetchGetAllPdfFiles)
      );
    })
    .catch((error) => {
      if (error && error.response && error.response.data) {
        if (
          error.response.data.error === "Project with that name already exists"
        ) {
          ShowToast("Project with that name already exists.");
        }
      }
      console.error("Error adding project:", error);
    });

  setNewProjectName("");
  setNewProjectDialogOpen(false);
};
